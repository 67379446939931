import { Line } from "react-chartjs-2";
import React from "react";
import { getDate } from "../functions";

const LineChart = ({
  statistics,
  selectedUser,
  selectedDate,
  selectedMode,
}) => {
  const promptsUsage = statistics.promptsUsageByTime;

  const prompts = Object.keys(promptsUsage || {});
  const labels = Object.values(promptsUsage || {})[0]?.usage.map(
    (_, index) => index + 1
  );
  const datasets = prompts.map((label) => ({
    label,
    data: promptsUsage[label].usage,
    backgroundColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.2)`,
    borderColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 1)`,
  }));

  const data = {
    labels,
    datasets,
  };

  return (
    <div className="w-full mt-4 bg-white p-4">
      <div className="p-2 text-center">
        <p className="text-md border-b-2 pb-2 border-gray-50">
          <span className="">Utilisation du prompts </span>
          <span className="font-semibold text-blue-600">
            {getDate(selectedDate, selectedMode)}{" "}
          </span>
          par
          <span className="font-semibold text-blue-600">
            {selectedUser.id
              ? ` ${selectedUser.firstName} ${selectedUser.lastName}`
              : " Toutes les utilisateurs"}
          </span>
        </p>
      </div>
      <Line
        className="p-4 "
        data={data}
        options={{
          aspectRatio: 5 / 3,
          layout: {
            padding: {
              top: 32,
              right: 16,
              bottom: 16,
              left: 8,
            },
          },
          elements: {
            line: {
              fill: false,
              tension: 0.4,
            },
          },
          responsive: true,
          skipNull: true,
          plugins: {
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.borderColor;
              },
              borderRadius: 4,
              color: "white",
              font: {
                weight: "bold",
              },
              formatter: Math.round,
              padding: 6,
            },
            legend: {
              position: "top",
            },
            title: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

export default LineChart;
