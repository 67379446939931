import axios from "../Prompts/utils/axios";
import React, { useEffect, useState } from "react";

function Translate() {
  const [responseMessage, setResponseMessage] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [pending, setPending] = useState(false);
  const items = [
    {
      name: "products",
      label: "Produits",
      model: "Product",
      frNameField: "Produit",
      enNameField: "productNameEn",
    },
    // {
    //   name: "productsKeywords",
    //   label: "Mots cle des produits",
    //   model: "Product",
    //   frNameField: "Keywords",
    //   enNameField: "Keywords_EN",
    // },
    // {
    //   name: "categories",
    //   label: "Categories",
    //   model: "Category",
    //   frNameField: "name",
    //   enNameField: "name_EN",
    // },
    // {
    //   name: "ebooks",
    //   label: "Ebooks",
    //   model: "Ebook",
    //   frNameField: "Name",
    //   enNameField: "Name_EN",
    // },
    // {
    //   name: "marketings",
    //   label: "Marketings",
    //   model: "Marketing",
    //   frNameField: "Name",
    //   enNameField: "Name_EN",
    // },
    // {
    //   name: "miniInfluencers",
    //   label: "Mini influenceurs",
    //   model: "MiniInflu",
    //   frNameField: "Name",
    //   enNameField: "Name_EN",
    // },
    // {
    //   name: "marketinglinks",
    //   label: "Liens marketing",
    //   model: "Marketinglinks",
    //   frNameField: "Title",
    //   enNameField: "Title_EN",
    // },
    // {
    //   name: "miniInfluLinks",
    //   label: "Liens Mini Influenseurs",
    //   model: "MiniInfluLinks",
    //   frNameField: "Title",
    //   enNameField: "Title_EN",
    // },
  ];
  useEffect(() => {
    if (pending) setResponseMessage("Traduction en cours ...");
  }, [pending]);

  const handleTranslate = async ({ model, frNameField, enNameField, name }) => {
    if (pending) return;
    setPending(true);
    console.log("item :", name);
    setSelectedCard(name);
    try {
      const res = await axios.post(
        `api/v1/translate-items`,
        {
          module: model,
          frNameField,
          enNameField,
        },
        {
          timeout: 180000,
        }
      );
      if (res) {
        console.log(res.data?.message);
        setPending(false);
        setResponseMessage(res.data?.message);
      }
    } catch (error) {
      console.error(`erreur lors du traduction des ${name} :`, error);
      setPending(false);
      setResponseMessage(error.response.data.message);
    }
  };

  return (
    <div className="flex flex-wrap w-full h-full p-6 items-start gap-3">
      {items.map((item, index) => (
        <div
          className="flex h-auto flex-col items-center max-w-72  bg-white border border-gray-300 rounded-3xl p-2 gap-2 justify-between"
          key={index}
          style={{ minHeight: 340 }}
        >
          <div className="flex flex-wrap w-full bg-yellow-500 border border-gray-300  text-orange-50 rounded-2xl items-center justify-center p-2">
            <p className="text-lg font-semibold">{item.label}</p>
          </div>
          <p className="text-md font-normal">
            En cliquant sur le bouton "Traduire", vous déclencherez la
            traduction automatique d'un maximum de 400 {item.label} qui n'ont
            pas encore été traduits dans la base de données et qui sont
            visibles.
          </p>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => handleTranslate(item)}
          >
            Traduire
          </button>
          {responseMessage && selectedCard === item.name && (
            <p className={`text-sm font-light text-center `}>
              {responseMessage}
            </p>
          )}
        </div>
      ))}
    </div>
  );
}

export default Translate;
