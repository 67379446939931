import React, { useState } from 'react';

const Datepicker = ({ handleDateSelect }) => {
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split('T')[0],
  );

  const handleChange = (event) => {
    handleDateSelect(event.target.value);
    setSelectedDate(event.target.value);
  };

  return (
    <div className="h-full">
      <input
        type="date"
        id="datepicker"
        value={selectedDate}
        onChange={handleChange}
        className="w-full h-[42px] rounded-md border border-gray-300 px-2 focus:outline-none focus:ring-1 focus:ring-blue-500 user-select-none"
      />
    </div>
  );
};

export default Datepicker;
