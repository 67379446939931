import axios from "axios";
import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import Modal from "../TableFactory/Modal";
import Button from "../TableFactory/Button";
import { BACKEND_URL } from "../../variables";
function Planifier() {
  const [ready, setReady] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newDate, setnewDate] = useState(null);
  const [state, setstate] = useState("");
  const token = window.localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/cron`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((r) => {
        setReady(true);
        setstate(r.data);
      });
  }, []);
  return (
    <div className="overflow-y-scroll overflow-x-scroll w-full h-full">
      <Toaster position="top-right" reverseOrder={false} />
      <div class="w-full mx-auto px-4 sm:px-8">
        <div className="w-full pt-10">
          <div className=" text-3xl font-bold">
            La publication du winners club du mois prochain est plannifier pour:
          </div>
          <div className="flex flex-row w-full">
            <div className="h-20 align-text-bottom flex items-center">
              Le {`${parseInt(state.replace("0 0 ", "").replace(" * *"))}`}{" "}
              <span className="transform -translate-y-2">
                {parseInt(state.replace("0 0 ", "").replace(" * *")) === 1
                  ? "er"
                  : "éme"}
              </span>{" "}
            </div>
            <div
              onClick={() => setShowModal(true)}
              className="rounded ml-5 text-white font-semibold border py-2 my-auto px-4 bg-green-300 hover:bg-white hover:text-green-300 cursor-pointer"
            >
              Modifier
            </div>
          </div>
        </div>
      </div>

      <Modal size="lg" active={showModal} toggler={() => setShowModal(false)}>
        <div className="flex flex-row w-full flex-wrap ">
          <div className="flex flex-row w-1/2 mt-5">
            <div className="ml-6  font-medium mr-3 my-auto w-80">
              Choisir une date
            </div>
            <select
              value={newDate}
              className="p-1 w-full outline-none border border-gray-100 rounded-lg"
              onChange={(e) => {
                e.preventDefault();
                console.log(e.target.value);
                setnewDate(e.target.value);
              }}
            >
              {[
                ...Array(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                  ).getDate()
                ).keys(),
              ].map((d) => (
                <option value={d + 1} onClick={() => {}}>
                  {d + 1}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full flex flex-row justify-end">
            <Button
              onClick={(e) => setShowModal(false)}
              className="rounded-lg px-4 py-2 text-red-500 hover:bg-red-100 mr-3 font-semibold"
            >
              Annuler
            </Button>
            <Button
              onClick={async (e) => {
                e.preventDefault();
                axios
                  .post(
                    `${BACKEND_URL}/cron`,
                    { cron: `0 0 ${newDate} * *` },
                    {
                      headers: {
                        authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((r) => {
                    setstate(r.data);
                    toast.success("Le changement est éffecture avec succés");
                    setShowModal(false);
                  })
                  .catch((err) => {
                    setShowModal(false);
                    toast.error("le changement de planification a échoué");
                  });
              }}
              className="rounded-lg px-4 py-2 bg-green-500 text-white font-semibold hover:text-gray-700 hover:bg-green-100 ml-6"
            >
              Changer
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Planifier;
