import React, { useState } from "react";
import { emptyField, randomUid } from "../../../../../utils";
import { useDispatch, useSelector } from "react-redux";

import { Fragment } from "react";
import Loading from "../../../../../../../Components/base/Loading";
import PromptInput from "./Input";
import { SetSelectedPrompt } from "../../../../../store/slice/promptsSlice";

const PromptInputs = () => {
  // Store
  const dispatch = useDispatch();
  const { selectedPrompt } = useSelector((state) => state.prompts);
  const [selectedInput, setSelectedInput] = useState("");

  const addNewInput = () => {
    const updatedPrompt = {
      ...selectedPrompt,
      fields: [...selectedPrompt.fields, { ...emptyField, _id: randomUid() }],
    };
    dispatch(SetSelectedPrompt(updatedPrompt));
  };

  if (!selectedPrompt) return <Loading withBg />;

  return (
    <Fragment>
      <div className="flex-1 overflow-auto pt-5" style={{ maxHeight: "72vh" }}>
        {selectedPrompt.fields.map((field) => (
          <PromptInput
            key={field._id}
            field={field}
            prompt={selectedPrompt}
            setSelectedInput={setSelectedInput}
            selectedInput={selectedInput}
          />
        ))}
      </div>
      <div className="w-full p-3 bg-white">
        <button
          className="block px-3 py-2 rounded w-full bg-blue-400 text-white"
          onClick={addNewInput}
        >
          Add Input Variable
        </button>
      </div>
    </Fragment>
  );
};

export default PromptInputs;
