import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import PromptContainer from "./PromptContainer";
import { Provider } from "react-redux";
import React from "react";
import store from "./store";

const Prompts = () => (
  <Provider store={store}>
    <PromptContainer />
  </Provider>
);

export default Prompts;
