const SimpleInput = ({ label, ...inputProps }) => {
  return (
    <div className="w-full mb-3">
      <label
        htmlFor={inputProps.name}
        className="block text-sm font-semibold mb-2"
      >
        {label}
      </label>
      <input {...inputProps} className="w-full border-2 px-4 py-3 rounded-md" />
    </div>
  );
};

export default SimpleInput;
