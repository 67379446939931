export const emptyField = {
  name: '',
  label: '',
  placeholder: '',
  required: true,
  width: 'medium',
  template: '',
};

export const emptyPrompt = {
  order: 0,
  label: '',
  published: false,
  model: {
    temperature: 1,
    stop: 'None',
    presencePenalty: 0,
    frequencyPenalty: 0,
    maxLength: 6000,
  },
  fields: [],
  requiredTemplate: '',
};

export function randomUid() {
  const groupLength = 4;
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < groupLength * 3; i++) {
    if (i > 0 && i % groupLength === 0) {
      result += '-';
    }
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const copyToClipboard = (str) => () => {
  navigator.clipboard.writeText(str);
};
