import React, { useState, useEffect } from "react";
import "./HoverImagePopup.css";

const ClickImagePopup = ({ images, altText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const toggleOverlay = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    // Cleanup event listener on component unmount or when `isOpen` changes
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isOpen]);

  if (!images) return null;

  return (
    <div className="click-image-popup">
      {/* Thumbnail image */}
      <img
        src={images[currentIndex]}
        alt={altText}
        className="thumbnail-image w-[40px] aspect-square"
        onClick={toggleOverlay} // Open popup on click
      />

      {/* Full-screen overlay that shows on click */}
      {isOpen && (
        <div className="fullscreen-overlay">
          <button className="close-button" onClick={toggleOverlay}>
            &times;
          </button>
          <button className="prev-button" onClick={prevImage}>
            &lt;
          </button>
          <img
            src={images[currentIndex]}
            alt={altText}
            className="fullscreen-image"
          />
          <button className="next-button" onClick={nextImage}>
            &gt;
          </button>
        </div>
      )}
    </div>
  );
};

export default ClickImagePopup;
