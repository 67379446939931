import axios from "axios";
import { BACKEND_URL } from "../../../variables";
const token = window.localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    authorization: `Bearer ${token}`,
  },
});

export default axiosInstance;
