import {
  RefreshList,
  RemoveSelectedPrompt,
  SetSelectedPrompt,
  UpdatePrompts,
} from "../../store/slice/promptsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import Loading from "../../../../Components/base/Loading";
import Modal from "../../../../Components/base/Modal";
import Tabs from "./tabs";
import axios from "../../utils/axios";
import { toast } from "react-toastify";

const EditPrompt = () => {
  // Stores
  const dispatch = useDispatch();
  const { selectedPrompt, prompts } = useSelector((state) => state.prompts);
  // States
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onShowConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const returnToPrompts = () => {
    dispatch(RemoveSelectedPrompt());
    setShowConfirmModal(false);
  };

  const onSuccessfulUpdated = (prompts) => {
    dispatch(UpdatePrompts(prompts));
    dispatch(RefreshList(true));
    dispatch(SetSelectedPrompt(null));
    toast.success("Prompt updated successfully");
  };

  const onSubmitPrompt = async () => {
    if (!selectedPrompt?.model?.modelId && selectedPrompt.promptType === "AI") {
      toast.error("Please select a model");
      return;
    }
    if (
      !selectedPrompt.fields.length &&
      selectedPrompt.promptType !== "bot_script"
    ) {
      toast.error("Please add at least one field");
      return;
    }

    if (selectedPrompt.requiredTemplate === "") {
      toast.error("Template can't be empty");
      return;
    }

    const updatedPrompts = prompts.map((prompt) => {
      if (prompt._id === selectedPrompt._id) return selectedPrompt;
      return prompt;
    });

    try {
      let newPayload = selectedPrompt;
      if (selectedPrompt.promptType !== "AI") {
        const { model, ...rest } = selectedPrompt;
        newPayload = rest;
      }
      await axios.patch(`api/v1/prompts/${selectedPrompt?._id}`, {
        ...newPayload,
        fields: selectedPrompt.fields.map((field) => {
          const { _id, ...rest } = field;
          return rest;
        }),
      });
      onSuccessfulUpdated(updatedPrompts);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchPrompt = async () => {
      try {
        if (!selectedPrompt) return;
        const res = await axios.get(`api/v1/prompts/${selectedPrompt._id}`);
        dispatch(SetSelectedPrompt(res.data));
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }
    };
    fetchPrompt();
  }, []);

  if (!selectedPrompt) return <></>;
  if (loading) return <Loading />;

  return (
    <div className="bg-gray-100 h-screen p-0 md:p-4 flex flex-col">
      <header
        className="md:flex items-center py-4 md:py-0 px-4 justify-between"
        style={{ minHeight: "10vh" }}
      >
        <div className="flex-1">
          <h3 className="text-gray-600 text-md flex items-center ">
            <span>Prompt ID: {selectedPrompt._id}</span>
          </h3>
          <h2 className="font-bold text-2xl mt-3 truncate w-3/4 md:w-1/3">
            {selectedPrompt.label}
          </h2>
        </div>
        <div className="block md:flex items-center">
          <button
            className="bg-white text-blue-700 text-base md:text-md px-2 md:px-4 mt-4 md:mr-4 md:mt-0 py-1 rounded"
            onClick={onShowConfirmModal}
          >
            Retour
          </button>
          <button
            type="button"
            className=" bg-blue-600 text-white text-base md:text-md px-2 md:px-4 mt-4 md:mt-0 py-1 rounded "
            onClick={onSubmitPrompt}
          >
            Enregistrer
          </button>
        </div>
      </header>
      <Tabs />
      {showConfirmModal && (
        <Modal title="Avertissement" onClose={closeConfirmModal}>
          <p className="text-gray-600 text-md">
            Voulez-vous vraiment revenir à la liste des prompt?
          </p>
          <p className="text-gray-600 text-md">
            Toutes les modifications seront perdues si vous ne sauvegardez pas.
          </p>
          <div className="text-right">
            <button
              type="submit"
              className=" bg-yellow-700 text-white px-4 py-1 rounded mt-4 text-md"
              onClick={returnToPrompts}
            >
              Confirmer
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default EditPrompt;
