import { IoCloseSharp } from 'react-icons/io5';
import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ children, title, onClose }) => {
  return ReactDOM.createPortal(
    <div
      style={{
        '--tw-gradient-from': 'rgba(0,0,0,.7)',
        '--tw-gradient-to': 'rgba(0,0,0,.7)',
      }}
      className="fixed z-50 bg-gradient-to-r from-gray-600 to-gray-600 flex w-full h-screen items-center justify-center"
    >
      <div className=" w-4/5 md:w-1/2 bg-white max-w-3xl ">
        <header className="p-4 border-b-2 border-gray-100 flex items-center justify-between">
          <h3 className=" font-semibold text-xl">{title}</h3>
          <i className="cursor-pointer" onClick={onClose}>
            <IoCloseSharp size={24} />
          </i>
        </header>
        <div className="p-4">{children}</div>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default Modal;
