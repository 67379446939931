import { Tab, Tabs, TabsHeader } from "@material-tailwind/react";

import React from "react";

const Modes = ({ handleModeSelect }) => {
  const modes = [
    {
      value: "day",
      label: "Day",
    },
    {
      value: "month",
      label: "Month",
    },
    {
      value: "year",
      label: "Year",
    },
  ];

  return (
    <div className="w-1/2 flex items-center">
      <Tabs value="month" className="h-full">
        <TabsHeader
          className="bg-gray-50"
          indicatorProps={{
            className:
              "bg-transparent border-2 border-gray-500 shadow-none rounded-md",
          }}
        >
          {modes.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => handleModeSelect(value)}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
      </Tabs>
    </div>
  );
};

export default Modes;
