import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';

import BarChart from './BarChart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineChart from './LineChart';
import PieChart from './PieChart';

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  RadialLinearScale,
  PointElement,
  ChartDataLabels,
);

const Charts = ({ statistics, selectedUser, selectedDate, selectedMode }) => {
  return (
    <div>
      <div className="w-full flex justify-around gap-4 ">
        <BarChart
          statistics={statistics}
          selectedUser={selectedUser}
          selectedDate={selectedDate}
          selectedMode={selectedMode}
        />
        <PieChart
          statistics={statistics}
          selectedUser={selectedUser}
          selectedDate={selectedDate}
          selectedMode={selectedMode}
        />
      </div>
      <LineChart
        statistics={statistics}
        selectedUser={selectedUser}
        selectedDate={selectedDate}
        selectedMode={selectedMode}
      />
    </div>
  );
};

export default Charts;
