import { generateRandomColors, getDate } from '../functions';

import { Pie } from 'react-chartjs-2';

const PieChart = ({ statistics, selectedUser, selectedDate, selectedMode }) => {
  const promptsUsage = statistics.promptUsage;
  const totalPrompts = statistics.totalPrompts;

  const modelPercentages = Object.entries(promptsUsage || {}).map(
    ([modelName, usage]) => ({
      label: modelName,
      value: (usage / totalPrompts) * 100,
    }),
  );

  const { colors, borderColors } = generateRandomColors(
    modelPercentages.length,
  );

  const data = {
    labels: modelPercentages.map((model) => model.label),
    datasets: [
      {
        data: modelPercentages.map((model) => model.value),
        backgroundColor: colors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="w-[40%] bg-white p-4 rounded-md">
      <div className="">
        <div className="p-2 text-center">
          <p className="text-md border-b-2 pb-2 border-gray-50">
            <span className="">Les prompts les plus utilisés </span>
            <span className="font-semibold text-blue-600">
              {getDate(selectedDate, selectedMode)}{' '}
            </span>
            par
            <span className="font-semibold text-blue-600">
              {selectedUser.id
                ? ` ${selectedUser.firstName} ${selectedUser.lastName}`
                : ' Toutes les utilisateurs'}
            </span>{' '}
            sont:
          </p>
        </div>
        <Pie
          options={{
            elements: {},
            responsive: true,
            plugins: {
              datalabels: {
                color: '#000',
                font: {
                  weight: 'bold',
                },
                formatter: (value, context) => {
                  return value.toFixed(2) + '%';
                },
              },
              legend: {
                position: 'top',
              },
              title: {
                display: false,
              },
            },
          }}
          data={data}
          className="w-full p-8"
        />
      </div>
    </div>
  );
};

export default PieChart;
