import React, { useEffect, useState } from "react";

import { BACKEND_URL } from "../../variables";
import Charts from "./charts/Charts";
import Datepicker from "./DatePicker";
import { FaWindowClose } from "react-icons/fa";
import Modes from "./Modes";
import UserSearch from "./UserSearch";
import axios from "axios";

const Statistics = () => {
  const token = localStorage.getItem("token");
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [selectedMode, setSelectedMode] = useState("month");
  const [statistics, setStatistics] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  const handleModeSelect = (mode) => {
    if (mode !== selectedMode) {
      setSelectedMode(mode);
    }
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const getStatistics = async () => {
    setLoading(true);
    const id = selectedUser.id || "";

    try {
      const response = await axios.post(
        `${BACKEND_URL}/api/v1/statistics`,
        {
          userId: String(id),
          dateString: selectedDate || new Date(),
          mode: selectedMode,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response;
      const statistics = data.data.statistics;
      setStatistics(statistics);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStatistics();
  }, [selectedDate, selectedMode, selectedUser]);

  return (
    <div className="w-full p-6 mx-auto bg-gray-100 h-full">
      <div className="flex flex-col gap-6">
        <div className="w-full flex items-center justify-between h-16 bg-white px-4 rounded-md shadow-sm">
          <div className="w-1/3 flex items-center gap-4">
            <UserSearch handleUserSelect={handleUserSelect} />
            <Datepicker handleDateSelect={handleDateSelect} />
          </div>
          <div className="w-1/3 flex items-center justify-center">
            <Modes handleModeSelect={handleModeSelect} />
          </div>
          <div className="w-1/3">
            <div className="rounded-md border-2 border-gray-50 p-2">
              <p className="flex items-center  gap-2">
                <span>Statistiques pour : </span>
                {selectedUser.id ? (
                  <p className=" p-1 rounded-md border-2 gap-2 border-gray-100 flex items-center w-max">
                    <span className="font-bold">
                      {selectedUser.firstName} {selectedUser.lastName}
                    </span>
                    <i
                      className="cursor-pointer"
                      onClick={() => setSelectedUser({})}
                    >
                      <FaWindowClose color="#fa4040" />
                    </i>
                  </p>
                ) : (
                  <span className="font-bold">Toutes les utilisateurs</span>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Charts
              statistics={statistics}
              selectedUser={selectedUser}
              selectedDate={selectedDate}
              selectedMode={selectedMode}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
