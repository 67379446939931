import React, { useEffect, useState } from "react";
import { BACKEND_URL } from "../../variables";
import axios from "axios";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import LineChart from "./LineChart";

const UserStatistics = () => {
  const token = localStorage.getItem("token");

  const [statistics, setStatistics] = useState({});

  useEffect(() => {
    const getUsersStatistics = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/api/v1/userStatistics`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        const { data } = response;
        setStatistics(data);
      } catch (err) {
        console.error(err);
      }
    };
    getUsersStatistics();
  }, []);

  return (
    <div className="w-full p-4 mx-auto bg-gray-100 h-full">
      <div className="flex flex-col gap-6">
        <div className="w-full flex items-center justify-between bg-white px-4 rounded-md shadow-sm">
          {/* <BarChart statistics={statistics?.bar || []} /> */}
          <PieChart
            statistics={statistics?.pie || []}
            registredUsers={statistics?.registered || 0}
          />
        </div>
        <div className="w-full bg-white p-2 rounded-md shadow-sm">
          <LineChart />
        </div>
      </div>
    </div>
  );
};

export default UserStatistics;
