import { useDispatch, useSelector } from "react-redux";

import Loading from "../../../../../../Components/base/Loading";
import SelectInput from "../../../../../../Components/base/SelectInput";
import { SetSelectedPrompt } from "../../../../store/slice/promptsSlice";
import SimpleInput from "../../../../../../Components/base/SimpleInput";

const EditModel = () => {
  // Store
  const dispatch = useDispatch();
  const { selectedPrompt, gptModels } = useSelector((state) => state.prompts);
  const promptTypes = [
    { _id: "AI", name: "AI" },
    { _id: "sitemap_script", name: "Sitemap Script" },
    { _id: "bot_script", name: "Bot Script" },
  ];
  const onPromptTypeChange = (event) => {
    const { value } = event.target;

    dispatch(
      SetSelectedPrompt({
        ...selectedPrompt,
        promptType: value,
      })
    );
  };
  const onModelChange = (event) => {
    const { name, value } = event.target;
    if (
      name === "max_tokens" &&
      value >
        gptModels.find((item) => item._id === selectedPrompt.model?.modelId)
          .max_tokens
    )
      return;
    if (name === "modelId") {
      if (value === "none")
        return dispatch(
          SetSelectedPrompt({
            ...selectedPrompt,
            model: {
              ...selectedPrompt.model,
              modelId: undefined,
              max_tokens: 512,
            },
          })
        );
      return dispatch(
        SetSelectedPrompt({
          ...selectedPrompt,
          model: {
            ...selectedPrompt.model,
            modelId: value,
            max_tokens: 512,
          },
        })
      );
    }
    dispatch(
      SetSelectedPrompt({
        ...selectedPrompt,
        model: { ...selectedPrompt.model, [name]: value },
      })
    );
  };

  if (!selectedPrompt) return <Loading withBg />;
  return (
    <div className="flex-1 overflow-auto pt-5" style={{ maxHeight: "72vh" }}>
      <form className="m-4">
        <div className="w-full mb-3">
          <label className="block text-sm font-semibold mb-2">
            Prompt Type
          </label>
          <select
            name="PromptType"
            className="w-full border-2 px-4 py-3 bg-white rounded-md"
            defaultValue={undefined}
            value={selectedPrompt.promptType}
            onChange={onPromptTypeChange}
          >
            <option value={"none"}>-- Prompt Type --</option>
            {promptTypes.map((option) => (
              <option
                key={option._id}
                value={option._id}
                className="capitalize"
              >
                {option.name}
              </option>
            ))}
          </select>
        </div>
        {selectedPrompt.promptType === "AI" && (
          <div className="w-full mb-3">
            <label className="block text-sm font-semibold mb-2">AI Model</label>
            <select
              name="modelId"
              className="w-full border-2 px-4 py-3 bg-white rounded-md"
              defaultValue={undefined}
              value={selectedPrompt.model?.modelId}
              onChange={onModelChange}
            >
              <option value={"none"}>-- modele AI --</option>
              {gptModels.map((option) => (
                <option
                  key={option._id}
                  value={option._id}
                  className="capitalize"
                >
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {selectedPrompt.model?.modelId &&
          selectedPrompt.promptType === "AI" && (
            <>
              <div className="w-full mb-3">
                <label className="block text-sm font-semibold mb-2">
                  Max tokens
                </label>
                <input
                  className="w-full border-2 px-4 py-3 bg-white rounded-md"
                  name="max_tokens"
                  type="number"
                  value={selectedPrompt.model?.max_tokens}
                  min={0}
                  onChange={onModelChange}
                />
              </div>
              <SelectInput
                name="temperature"
                label="Temperature"
                defaultValue={selectedPrompt.model?.temperature}
                onChange={onModelChange}
                options={[
                  "0.0",
                  "0.5",
                  "0.7",
                  "0.9",
                  "1.0",
                  "1.2",
                  "1.5",
                  "2.0",
                ]}
              />
              <div className="w-full flex items-center gap-3">
                <div className="w-1/2">
                  <SimpleInput
                    type="number"
                    name="maxLength"
                    label="Max length"
                    value={selectedPrompt.model?.maxLength}
                    onChange={onModelChange}
                    max={1000}
                    min={1}
                  />
                </div>
                <div className="w-1/2">
                  <SelectInput
                    name="stop"
                    label="Stop"
                    defaultValue={selectedPrompt.model?.stop}
                    onChange={onModelChange}
                    options={["None", ".", "Newline", "###"]}
                  />
                </div>
              </div>
              <div className="w-full flex items-center gap-3">
                <div className="w-1/2">
                  <SelectInput
                    name="presencePenalty"
                    label="Presence penalty"
                    defaultValue={selectedPrompt.model?.presencePenalty}
                    onChange={onModelChange}
                    options={[
                      "0.0",
                      "0.5",
                      "0.7",
                      "0.9",
                      "1.0",
                      "1.2",
                      "1.5",
                      "2.0",
                    ]}
                  />
                </div>
                <div className="w-1/2">
                  <SelectInput
                    name="frequencyPenalty"
                    label="Frequency penalty"
                    defaultValue={selectedPrompt.model?.frequencyPenalty}
                    onChange={onModelChange}
                    options={[
                      "0.0",
                      "0.5",
                      "0.7",
                      "0.9",
                      "1.0",
                      "1.2",
                      "1.5",
                      "2.0",
                    ]}
                  />
                </div>
              </div>
            </>
          )}
      </form>
    </div>
  );
};

export default EditModel;
