import Edit from './prompt/Edit';
import { useState } from 'react';

const tabs = [
  {
    id: 'prompt',
    label: 'Prompt',
    element: <Edit />,
  },
];

const Tabs = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const onTabChange = (tabId) => () => {
    setSelectedTab(tabId);
  };

  return (
    <div
      className="flex-1 flex flex-col overflow-x-hidden"
      style={{ height: '90vh' }}
    >
      <div className="flex-1 px-6 md:px-0">
        <Edit />
      </div>
    </div>
  );
};

export default Tabs;

/* <div className="border-b-2 h-12 border-gray-200 flex items-center mt-4">
  {tabs.map((tab) => {
    const isActive = tab.id === selectedTab.id;
    return (
      <span
        key={tab.id}
        className={`block text-sm font-medium pt-2 pb-3 cursor-pointer mt-0.5 ${
          isActive
            ? ' text-blue-500 border-blue-500'
            : 'text-gray-700 border-transparent'
        } mr-6 h-full border-b-2`}
        onClick={onTabChange(tab)}
      >
        {tab.label}
      </span>
    );
  })}
</div> */
