import { Link } from "react-router-dom";

const MenuButtons = ({ item, index, setactiveTab, activeTab, toggle }) => (
  <Link
    key={index}
    onClick={() => setactiveTab(`/${item[0]}`)}
    to={`/${item[0]}`}
    className={`flex w-full text-lg justify-between  ${
      activeTab == `/${item[0]}` ? "bg-yellow-300" : "bg-yellow-50"
    } hover:bg-yellow-300 text-green-600 cursor-pointer items-center mb-4`}
  >
    <div className="flex items-center">
      <svg
        title="test"
        xmlns="http://www.w3.org/2000/svg"
        className="ml-3 icon text-lg icon-tabler icon-tabler-puzzle"
        width={28}
        height={28}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1" />
      </svg>
      <span
        className={`ml-4 text-lg font-semibold transition-all ${
          toggle ? "block" : "hidden"
        }`}
      >
        {item[1]}
      </span>
    </div>
  </Link>
);

export default MenuButtons;
