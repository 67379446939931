import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Loading from "../../Components/base/Loading";
import SimpleInput from "../../Components/base/SimpleInput";
import Modal from "../../Components/base/Modal";
import { BACKEND_URL } from "../../variables";

const Seo = () => {
  // State for the modal and SEO rate limit
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const [isLimitKeyWordsModalOpen, setIsLimitKeywordsModalOpen] =
    useState(false);
  const [seoRateLimit, setSeoRateLimit] = useState(0);
  const [seoKeywordsLimit, setSeoKeywordsLimit] = useState(0);
  const [loading, setLoading] = useState(false);
  const token = window.localStorage.getItem("token");

  // Modal open/close handlers
  const onLimitModalOpen = () => {
    setIsLimitKeywordsModalOpen(false);
    setIsLimitModalOpen(true);
  };
  const onLimitModalClose = () => setIsLimitModalOpen(false);

  // Modal Keywords open/close handlers
  const onLimitKeywordsModalOpen = () => {
    setIsLimitModalOpen(false);

    setIsLimitKeywordsModalOpen(true);
  };
  const onLimitKeywordsModalClose = () => setIsLimitKeywordsModalOpen(false);
  // Fetch SEO rate limit data
  useEffect(() => {
    const getSeoRateLimit = async () => {
      setLoading(true);
      if (!token) return;
      try {
        const res = await axios.get(`${BACKEND_URL}/api/v1/seoRateLimit`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        if (res.status === 200 && res.data?.data?.limit) {
          setSeoRateLimit(res.data.data.limit);
        }
      } catch (error) {
        toast.error("Failed to fetch SEO rate limit.");
      } finally {
        setLoading(false);
      }
    };
    const getSeoKeywordsLimit = async () => {
      setLoading(true);
      if (!token) return;
      try {
        const res = await axios.get(`${BACKEND_URL}/api/v1/seoKeywordsLimit`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        if (res.status === 200 && res.data?.data?.KeywordsLimit) {
          setSeoKeywordsLimit(res.data.data.KeywordsLimit);
        }
      } catch (error) {
        toast.error("Failed to fetch SEO Keywords limit.");
      } finally {
        setLoading(false);
      }
    };
    getSeoRateLimit();
    getSeoKeywordsLimit();
  }, []);

  // Submit new SEO rate limit
  const onSubmitSeoRateLimit = async (event) => {
    event.preventDefault();
    if (!token) return;

    try {
      setLoading(true);
      const res = await axios.put(
        `${BACKEND_URL}/api/v1/seoRateLimit`,
        {
          limit: seoRateLimit,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if (res?.data?.message && res?.status === 200) {
        toast.success(res.data.message);
      }
    } catch (err) {
      toast.error("Failed to update SEO rate limit.");
    } finally {
      setLoading(false);
      onLimitModalClose();
    }
  };

  // Submit new SEO rate limit
  const onSubmitSeoKeywordsLimit = async (event) => {
    event.preventDefault();
    if (!token) return;

    try {
      setLoading(true);
      const res = await axios.put(
        `${BACKEND_URL}/api/v1/seoKeywordsLimit`,
        {
          limit: seoKeywordsLimit,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if (res?.data?.message && res?.status === 200) {
        toast.success(res.data.message);
      }
    } catch (err) {
      toast.error("Failed to update SEO rate limit.");
    } finally {
      setLoading(false);
      onLimitKeywordsModalClose();
    }
  };
  if (loading) return <Loading />;

  return (
    <div className="p-3 md:p-6 bg-gray-100 h-full overflow-hidden">
      <div className="max-w-screen-2xl w-full m-auto flex flex-col">
        <header className="flex justify-start gap-2.5">
          {/* <h2 className="font-bold text-xl">SEO Rate Limit</h2> */}
          <button
            className="bg-blue-600 text-white text-sm py-2 px-3 rounded"
            onClick={onLimitModalOpen}
          >
            SEO Limit d'utilisation
          </button>
          <button
            className="bg-blue-600 text-white text-sm py-2 px-3 rounded"
            onClick={onLimitKeywordsModalOpen}
          >
            Configure Le nombre des mots clés associés
          </button>
        </header>

        {isLimitModalOpen && (
          <Modal
            title="Configurer la limite quotidienne des requetes SEO"
            onClose={onLimitModalClose}
          >
            <form onSubmit={onSubmitSeoRateLimit}>
              <SimpleInput
                label="La limite SEO quotidienne"
                type="number"
                min="0"
                name="limit"
                value={seoRateLimit}
                onChange={(e) => setSeoRateLimit(e.target.value)}
              />
              <div className="text-right">
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-4 py-1 rounded mt-4 text-md"
                >
                  Sauvegarder
                </button>
              </div>
            </form>
          </Modal>
        )}
        {isLimitKeyWordsModalOpen && (
          <Modal
            title="Le nombre des mots clés associés"
            onClose={onLimitKeywordsModalClose}
          >
            <form onSubmit={onSubmitSeoKeywordsLimit}>
              <SimpleInput
                label="Nombre des mots clés associés:"
                type="number"
                min="0"
                name="keywords"
                value={seoKeywordsLimit}
                onChange={(e) => setSeoKeywordsLimit(e.target.value)}
              />
              <div className="text-right">
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-4 py-1 rounded mt-4 text-md"
                >
                  Sauvegarder
                </button>
              </div>
            </form>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Seo;
