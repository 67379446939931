import { configureStore } from "@reduxjs/toolkit";
import promptsSlice from "./slice/promptsSlice";

const store = configureStore({
  reducer: {
    prompts: promptsSlice,
  },
});

export default store;
