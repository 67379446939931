import "./producttable.css";
import "./producttable.css";

import { BACKEND_URL } from "../../variables";
import {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import React, { Fragment, useEffect, useState } from "react";
import {
  genericPatchVisibility,
  genericVisibilityChange,
} from "../../utils/generic";
import toast, { Toaster } from "react-hot-toast";

import Button from "./Button";
import { CSVReader } from "react-papaparse";
import CreatableSelect from "react-select/creatable";
import { DEBOUNCE_TYPING_DELAY } from "../../utils/contants";
import { DebounceInput } from "react-debounce-input";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import readXlsxFile from "read-excel-file";
import SimpleInput from "../base/SimpleInput";
import HoverImagePopup from "../hoverImagePopup/HoverImagePopup";

export default function ProductTable(props) {
  // table view data
  const [Data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showKeywordsModal, setShowKeywordsModal] = useState(false);
  const [selectedKeywordIndex, setSelectedKeywordIndex] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [scarpedBulk, setScarpedBulk] = useState([]);
  const [scarpedBulkError, setScarpedBulkErrors] = useState([]);
  const [selectedproduct, setselectedproduct] = useState({});
  const [end, setEnd] = useState(true);
  // state for creation form
  const [showModal, setShowModal] = useState(false);
  const [scraped, setScraped] = useState({ loading: false, ready: false });
  const [selectedImages, setselectedImages] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [url, setUrl] = useState("");
  const [search, setSearch] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [thisrow, setThisrow] = useState({});
  const [Niche, setNiche] = useState("");
  const [active, setActive] = useState("");
  const [order, setOrder] = useState("DESC");
  const [checked, setChecked] = useState("");
  const [carouselImages, setCarouselImages] = useState([]);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [exchangeRateModal, setExchangeRateModal] = useState(false);
  const [checkedCarrousel, setCheckedCarrousel] = useState("");
  const token = window.localStorage.getItem("token");
  const [visibilityChanges, setVisibilityChanges] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState(null);

  const visibitilyQuery = {
    // VisibleThisMonth: active === "this" ? true : "",
    // VisibleNextMonth: active === "next" ? true : "",
    VisibleAllTime:
      active === "allTime" ? true : active === "NotVisible" ? false : "",
    // NotVisible: active === "NotVisible" ? true : "",
  };
  const onProductDelete = (product) => {
    setDeleteProduct(product); // Store the ID of the product to delete
    setShowDeleteModal(true); // Open the modal
  };

  // const { CSVReader } = useCSVReader();
  const handleFileUpload = async (event) => {
    const fileInput = event.target;

    if (fileInput?.files?.length > 0) {
      const files = Array.from(fileInput.files); // Convert FileList to an array

      // Create an array to store the promises for each file upload
      const uploadPromises = [];

      files.forEach((file) => {
        const formData = new FormData();
        formData.append("File", file);
        formData.append("isImage", true);
        formData.append("prefix", `product-${selectedproduct.id}`);

        // Push each upload promise to the array
        uploadPromises.push(
          axios.post(`${BACKEND_URL}/api/v1/file-upload`, formData, {
            headers: {
              authorization: `Bearer ${token}`,
            },
          })
        );
      });

      // Wait for all uploads to complete
      Promise.all(uploadPromises)
        .then((responses) => {
          const uploadedUrls = responses.map((response) => ({
            url: response?.data?.fileUrl,
            isCarousel: true,
          }));

          // Update the state with the uploaded URLs
          setselectedImages([...selectedImages, ...uploadedUrls]);
          setselectedproduct({
            ...selectedproduct,
            Images: JSON.stringify([
              ...JSON.parse(selectedproduct?.Images),
              ...uploadedUrls,
            ]),
          });
          setCarouselImages([...carouselImages, ...uploadedUrls]);
        })
        .catch((error) => {
          toast.error("😕 Error uploading files: try again ");
        });
    }
  };

  const onSubmitExchangerate = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.put(
        `${BACKEND_URL}/api/v1/exchangeRate/`,
        {
          newExchangeRate: exchangeRate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      );

      if (res?.data?.message && res?.status === 200)
        toast.success(res.data.message);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setExchangeRateModal(false);
    }
  };

  const handleVisibilityChange = (valueToChange, fullValueToChange, i) => {
    genericVisibilityChange(
      "Produit",
      i,
      valueToChange,
      fullValueToChange,
      visibilityChanges,
      setVisibilityChanges,
      Data,
      setData
    );
  };

  const refresh = () => {
    axios
      .post(
        `${BACKEND_URL}/api/v1/product/search`,
        {
          page: 0,
          Produit: search,
          Niche: Niche,
          ...visibitilyQuery,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPage(0);
        setData(data.data);
      })
      .catch((err) => console.log(err));
  };
  const fetchData = () => {
    axios
      .post(
        `${BACKEND_URL}/api/v1/product/search`,
        {
          page: page,
          Produit: search,
          Niche: Niche,
          Order: order,
          ...visibitilyQuery,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPage(page + 1);
        if (data.data.length === 0 || data.data.length < 20) setEnd(false);
        else if (!end) {
          setEnd(true);
        }
        setData([...Data, ...data.data]);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    axios
      .post(
        `${BACKEND_URL}/api/v1/product/search`,
        {
          page: 0,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPage(page + 1);
        setData(data.data);
      })
      .catch((err) => console.log(err));
  }, [props]);

  useEffect(() => {
    fetchData();
  }, [Niche, active, order, search]);
  useEffect(() => {
    const getExhangeRate = async () => {
      const gptEchangeLimitRes = await axios.get(
        `${BACKEND_URL}/api/v1/exchangeRate/`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (
        gptEchangeLimitRes &&
        gptEchangeLimitRes.data?.data?.exchangeRate &&
        gptEchangeLimitRes.status === 200
      )
        setExchangeRate(gptEchangeLimitRes.data.data.exchangeRate);
    };
    getExhangeRate();
  }, []);

  return (
    <div className="overflow-y-scroll overflow-x-scroll w-full h-full">
      <Toaster position="top-right" reverseOrder={false} />
      <div class="w-full mx-auto px-4 sm:px-8">
        <div class="py-4">
          <div>
            <h2 class="text-2xl font-semibold leading-tight">{props.Name}</h2>
          </div>
          <div class="my-2 flex sm:flex-row flex-col justify-between">
            <div class="flex">
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative w-1/3">
                  <select
                    value={Niche}
                    onChange={(e) => {
                      setNiche(e.target.value);
                      setPage(0);
                      setData([]);
                    }}
                    class="h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value={""}>Toutes les niches</option>
                    {props.Category.map((cat) => (
                      <option value={cat.name}>{cat.name}</option>
                    ))}{" "}
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <div class="relative w-1/3">
                  <select
                    value={active}
                    onChange={(e) => {
                      setActive(e.target.value);
                      setPage(0);
                      setData([]);
                    }}
                    class="appearance-none h-full rounded-r border-t sm:rounded-r-none border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option value={""}>Tout</option>
                    {/* <option value="this">Active ce mois</option>
                    <option value="next">Active le mois prochain</option> */}
                    <option value="allTime">Visible</option>
                    <option value="NotVisible">Non Visible</option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <div class="relative w-1/3">
                  <select
                    value={order}
                    onChange={(e) => {
                      setOrder(e.target.value);
                      setPage(0);
                      setData([]);
                    }}
                    class="appearance-none h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option value="DESC" selected>
                      Plus récent
                    </option>
                    <option value="ASC">Plus ancien</option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                  <svg
                    viewBox="0 0 24 24"
                    class="h-4 w-4 fill-current text-gray-500"
                  >
                    <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                  </svg>
                </span>
                <DebounceInput
                  minLength={1}
                  debounceTimeout={DEBOUNCE_TYPING_DELAY}
                  onChange={(event) => {
                    setPage(0);
                    setData([]);
                    setSearch(event.target.value);
                  }}
                  class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                />
              </div>
            </div>
            <div className="w-96  flex-row flex justify-evenly items-center">
              {Object.keys(visibilityChanges).length !== 0 && (
                <div
                  onClick={async () => {
                    setShowSaveModal(true);
                  }}
                  className="border rounded-lg px-3 py-1 cursor-pointer font-semibold hover:bg-yellow-200"
                >
                  Enregistrer
                </div>
              )}
              {Object.keys(visibilityChanges).length !== 0 && (
                <div
                  onClick={async () => {
                    setVisibilityChanges({});
                    setPage(0);
                    setData([]);
                    refresh();
                  }}
                  className="border rounded-lg px-3 py-1 cursor-pointer font-semibold hover:bg-yellow-200"
                >
                  Rafraichir
                </div>
              )}
              <div
                onClick={async () => {
                  setShowBulkModal(true);
                }}
                className="border rounded-lg px-3 py-1 cursor-pointer font-semibold hover:bg-yellow-200"
              >
                Importer
              </div>
              <div
                onClick={async () => {
                  setShowModal(true);
                }}
                className="border rounded-lg px-3 py-1 cursor-pointer font-semibold hover:bg-yellow-200"
              >
                Créer
              </div>
              <button
                className="bg-blue-600 text-white text-sm py-2 px-3 rounded"
                onClick={() => setExchangeRateModal(true)}
              >
                Configurer le taux de change
              </button>
            </div>
          </div>

          <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <InfiniteScroll
                dataLength={Data.length} //This is important field to render the next data
                next={fetchData}
                hasMore={end}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                <table class="min-w-full leading-normal">
                  <thead>
                    <tr>
                      {props.columns?.map((i) => (
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          {i}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((i, index) => (
                      <>
                        <tr
                          key={index}
                          onDoubleClick={(e) => {
                            setselectedproduct(i);
                            setselectedImages(JSON.parse(i.Images));
                            setChecked(JSON.parse(i.Images)[0]);
                            setCarouselImages(
                              JSON.parse(i.Images)?.filter(
                                (item) => item.isCarousel
                              )
                            );
                            setShowEditModal(!showEditModal);
                          }}
                          className={`group ${i.featured && "featured"}`}
                        >
                          <td class="group-hover:bg-gray-50 px-5 py-3 border-b border-gray-200 bg-white text-sm w-20">
                            <div class="product h-auto w-20 ">{i.id}</div>
                          </td>
                          <td class="group-hover:bg-gray-50 px-5 py-3 border-b border-gray-200 bg-white text-sm w-[110px]">
                            <button
                              class="product h-[40px] w-[110px] px-4 py-2 bg-red-500 text-white font-semibold rounded-md shadow-sm hover:bg-red-600 transition duration-200 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2"
                              onClick={() => onProductDelete(i)}
                            >
                              Supprimer
                            </button>
                          </td>

                          <td class="group-hover:bg-gray-50 px-5 py-3 border-b border-gray-200 bg-white text-sm w-56">
                            <div class="product h-auto w-56 ">{i.Produit}</div>
                          </td>
                          <td class="group-hover:bg-gray-50 px-5 py-3 border-b border-gray-200 bg-white text-sm ">
                            <div class="product h-auto w-56 product-image-overlay ">
                              <HoverImagePopup
                                images={JSON.parse(i?.Images)?.map(
                                  (item) => item?.url
                                )}
                                altText="Image description"
                              />
                            </div>
                          </td>
                          <td class="group-hover:bg-gray-50 px-5 py-3 border-b border-gray-200 bg-white text-sm">
                            <div class="product h-auto w-56 ">
                              {moment(i.createdAt).format("DD/MM/YY hh:mm")}
                            </div>
                          </td>
                          <td class="group-hover:bg-gray-50 px-5 py-2 border-b border-gray-200 bg-white text-sm">
                            <a
                              target={"_blank"}
                              className="underline text-blue-400"
                              href={i.Lien_AgentEnfinLibre}
                            >
                              {" "}
                              Link
                            </a>
                          </td>
                          <td class="group-hover:bg-gray-50 px-5 py-2 border-b border-gray-200 bg-white text-sm">
                            <a
                              target={"_blank"}
                              className="underline text-blue-400"
                              href={i.Lien_Aliexpress}
                            >
                              {" "}
                              Link
                            </a>
                          </td>
                          <td class="group-hover:bg-gray-50 px-5 py-3 border-b border-gray-200 bg-white text-sm w-56">
                            <div class="product h-auto w-56 ">{i.Niche}</div>
                          </td>
                          <td class="px-5 py-5 border-b group-hover:bg-gray-50  border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-no-wrap">
                              {i.NOMBRE_DE_COMMANDES}
                            </p>
                          </td>
                          <td class=" group-hover:bg-gray-50 px-5 py-2 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-no-wrap">
                              {i.Nombre_avis_client}
                            </p>
                          </td>
                          <td class="  group-hover:bg-gray-50 px-5 py-2 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-no-wrap">
                              {i.Note}
                            </p>
                          </td>
                          <td class=" group-hover:bg-gray-50 px-5 py-2 border-b border-gray-200 bg-white text-sm">
                            <a
                              target={"_blank"}
                              className="underline text-blue-400"
                              href={i.Lien_Publicité}
                            >
                              {" "}
                              Link
                            </a>
                          </td>
                          <td class=" group-hover:bg-gray-50 px-5 py-2 border-b border-gray-200 bg-white text-sm">
                            <a
                              target={"_blank"}
                              className="underline text-blue-400"
                              href={i.Video_Winners_Club}
                            >
                              {" "}
                              Link
                            </a>
                          </td>
                          <td class=" group-hover:bg-gray-50 px-5 py-2 border-b border-gray-200 bg-white text-sm">
                            <a
                              target={"_blank"}
                              className="underline text-blue-400"
                              href={i.Miniature_Winners_Club}
                            >
                              {" "}
                              Link
                            </a>
                          </td>
                          <td className="group-hover:bg-gray-50 px-5 py-2 border-b border-gray-200 bg-white text-sm">
                            <Button
                              color="blue"
                              buttonType="link"
                              onClick={(e) => {
                                setSelectedKeywordIndex(index); // Définir l'index du modal actuel
                                setShowKeywordsModal(true);
                              }}
                              ripple="dark"
                            >
                              Voir
                            </Button>
                          </td>

                          {/*TODO Add all time visibility */}
                          <td class=" group-hover:bg-gray-50 px-5 py-2 border-b border-gray-200 bg-white text-sm">
                            <input
                              type="checkbox"
                              className="h-4 w-4 bg-blue-200 outline-none"
                              checked={
                                i.VisibleAllTime === null
                                  ? false
                                  : i.VisibleAllTime
                              }
                              onChange={() => {}}
                              onClick={() =>
                                handleVisibilityChange(
                                  "allTime",
                                  "VisibleAllTime",
                                  i
                                )
                              }
                            />
                          </td>
                          <td class=" group-hover:bg-gray-50 px-5 py-2 border-b border-gray-200 bg-white text-sm">
                            <p class="text-gray-900 whitespace-no-wrap">
                              {i.productNameEn}
                            </p>
                          </td>
                        </tr>
                        {selectedKeywordIndex === index && (
                          <Modal
                            size="lg"
                            active={showKeywordsModal}
                            toggler={() => {
                              setShowKeywordsModal(false);
                            }}
                          >
                            <DialogBody>
                              <div className="flex flex-wrap flex-row w-full justify-start ">
                                {(() => {
                                  try {
                                    return JSON.parse(i.Keywords)?.map(
                                      (keyword) => (
                                        <span className="mr-3 relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                          <span
                                            aria-hidden
                                            className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                          ></span>
                                          <span className="relative">
                                            {keyword.label}
                                          </span>
                                        </span>
                                      )
                                    );
                                  } catch (err) {
                                    return i.Keywords;
                                  }
                                })()}
                              </div>
                            </DialogBody>
                            <DialogFooter>
                              <Button
                                color="red"
                                buttonType="link"
                                onClick={() => {
                                  setShowKeywordsModal(false);
                                }}
                                ripple="dark"
                              >
                                fermer
                              </Button>
                            </DialogFooter>
                          </Modal>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
              <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
                <span class="text-xs xs:text-sm text-gray-900">
                  Showing 1 to 4 of 50 Entries
                </span>
                <div class="inline-flex mt-2 xs:mt-0">
                  <button class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                    Prev
                  </button>
                  <button class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeleteModal && (
        <Modal
          size="sm"
          active={showDeleteModal}
          toggler={() => setShowDeleteModal(false)}
        >
          <DialogHeader>
            <h2 className="text-lg font-semibold">Confirmer la suppression</h2>
          </DialogHeader>
          <DialogBody>
            <p>
              Êtes-vous sûr de vouloir supprimer ce produit ? Cette action est
              irréversible.
            </p>
          </DialogBody>
          <DialogFooter>
            <button
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 transition"
              onClick={() => setShowDeleteModal(false)}
            >
              Annuler
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition"
              onClick={() => {
                axios
                  .post(
                    `${BACKEND_URL}/api/v1/productdelete`,
                    { ...deleteProduct }, // Use selected product for deletion
                    {
                      headers: {
                        authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then(() => {
                    refresh();
                    toast.success("🥳 un produit a été supprimé");
                  })
                  .catch(() =>
                    toast.error("Échec de la suppression du produit.")
                  )
                  .finally(() => {
                    setShowDeleteModal(false); // Close modal
                    setDeleteProduct(null); // Clear selected product
                  });
              }}
            >
              Supprimer
            </button>
          </DialogFooter>
        </Modal>
      )}

      <Modal
        size="lg"
        active={showSaveModal}
        toggler={() => {
          setShowSaveModal(false);
        }}
      >
        <DialogBody>
          <div class="flex flex-col justify-center items-center">
            <h1 class="font-large text-xl pb-3">
              Veuillez confirmer les changements
            </h1>
            <table class="bg-white text-gray-900 table-auto border-separate w-full shadow-md">
              <thead>
                <tr>
                  <th class="bg-blue-700 text-white p-1 w-auto">Nom Produit</th>
                  <th class="bg-blue-700 text-white p-1">Visible</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(visibilityChanges).map((item, index) => {
                  return (
                    <tr key={index} class="bg-blue-100 text-blue-900">
                      <td class="p-1 flex flex-row justify-center items-center">
                        {visibilityChanges[item].name}
                      </td>
                      <td class="p-1">
                        <div class="flex flex-row justify-center items-center">
                          <input
                            type="checkbox"
                            id="old all time"
                            checked={visibilityChanges[item].old.allTime}
                          />
                          <p>&emsp;&rarr;&emsp;</p>
                          <input
                            type="checkbox"
                            id="new all time"
                            checked={visibilityChanges[item].new.allTime}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            color="red"
            buttonType="link"
            onClick={(e) => {
              setShowSaveModal(false);
            }}
            ripple="dark"
          >
            fermer
          </Button>

          <Button
            color="green"
            onClick={() => {
              genericPatchVisibility(
                "product",
                BACKEND_URL,
                visibilityChanges,
                token,
                setShowSaveModal,
                setVisibilityChanges,
                refresh
              );
            }}
            ripple="light"
          >
            Confirmer
          </Button>
        </DialogFooter>
      </Modal>
      <Modal
        size="lg"
        active={showModal}
        toggler={() => {
          setselectedImages([]);
          setScraped({ loading: false, ready: false });
          setShowModal(false);
        }}
      >
        <DialogHeader
          toggler={() => {
            setselectedImages([]);
            setScraped({ loading: false, ready: false });
            setShowModal(false);
          }}
        ></DialogHeader>
        <DialogBody>
          <div className="flex flex-row ">
            <div className="flex flex-row ">
              <div className="my-auto mr-7 font-medium">
                Entrer le lien AliExpress
              </div>
              <input
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="lien"
                className="border-gray-100 border rounded-lg outline-none px-1"
              />
            </div>
            <div
              onClick={async () => {
                setScraped({ ...scraped, loading: true });
                axios
                  .post(
                    `${BACKEND_URL}/api/v1/scrapeproduct`,
                    { url: url },
                    {
                      headers: {
                        authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((response) => {
                    setScraped({
                      loading: false,
                      ready: true,
                      ...response.data,
                      url: url,
                    });
                    setselectedImages(response.data.images);
                  })
                  .catch((err) => console.log("oopsie"));
              }}
              className="ml-6 border rounded-2xl hover:bg-purple-200 py-2 px-6  font-semibold border-gray-200 cursor-pointer"
            >
              Charger
            </div>
          </div>
          <div className="mt-5 flex flex-col">
            {scraped.loading && <div>loading</div>}
            {scraped.ready && (
              <div className="w-full flex flex-col">
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="  font-medium mr-3 my-auto w-48 mb-9">
                    choisir une niche
                  </div>
                  <Select
                    className={` basic-single w-full h-11 ${
                      scraped.Niche
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    }`}
                    classNamePrefix="select"
                    value={{
                      label: scraped.Niche || "",
                      value: scraped.Niche || "",
                    }}
                    onChange={(e) => setScraped({ ...scraped, Niche: e.value })}
                    isClearable={false}
                    isSearchable={false}
                    name="color"
                    options={props.Category.map((i) => {
                      return { label: i.name, value: i.name };
                    })}
                  />
                </div>
                <div className="flex flex-row">
                  <div className="flex flex-row w-1/2">
                    <div className="font-medium mr-3 my-auto w-48">
                      Nom du produit:
                    </div>
                    <textarea
                      placeholder="Nom"
                      value={scraped.title}
                      onChange={(e) =>
                        setScraped({ ...scraped, title: e.target.value })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.title
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                      rows="5"
                    />
                  </div>
                  <div className="flex flex-row w-1/2">
                    <div className="ml-6 font-medium mr-3 my-auto w-80">
                      Nombre de commandes:
                    </div>
                    <input
                      placeholder="Nom"
                      value={scraped.orders}
                      onChange={(e) =>
                        setScraped({ ...scraped, orders: e.target.value })
                      }
                      className={`p-1 w-full ${
                        scraped.orders
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      }  outline-none h-1/3 my-auto  rounded-lg`}
                    />
                  </div>
                </div>
                <div className="flex flex-row mt-4">
                  <div className="flex flex-row w-1/2">
                    <div className=" font-medium mr-3 my-auto w-48">
                      Nombre d'avis:
                    </div>
                    <input
                      placeholder="Nombre d'avis"
                      value={scraped.ratings.totalStartCount}
                      onChange={(e) =>
                        setScraped({
                          ...scraped,
                          ratings: {
                            ...scraped.ratings,
                            totalStartCount: e.target.value,
                          },
                        })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.ratings.totalStartCount
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                    />
                  </div>
                  <div className="flex flex-row w-1/2 ">
                    <div className="ml-6  font-medium mr-3 my-auto w-48">
                      Note:
                    </div>
                    <input
                      placeholder="Note:"
                      value={scraped.ratings.averageStar}
                      onChange={(e) =>
                        setScraped({
                          ...scraped,
                          ratings: {
                            ...scraped.ratings,
                            averageStar: e.target.value,
                          },
                        })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.ratings.averageStar
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                    />
                  </div>
                </div>
                <div className="flex flex-row mt-4">
                  <div className="flex flex-row w-1/2">
                    <div className=" font-medium mr-3 my-auto w-48">
                      Lien publicité:
                    </div>
                    <input
                      placeholder="Lien publicité"
                      value={scraped.AdLink}
                      onChange={(e) =>
                        setScraped({ ...scraped, AdLink: e.target.value })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.AdLink
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                    />
                  </div>
                  <div className="flex flex-row w-1/2">
                    <div className="ml-6  font-medium mr-3 my-auto w-48">
                      Video Winners Club:
                    </div>
                    <input
                      placeholder="Video Winners Club"
                      value={scraped.VideoLink}
                      onChange={(e) =>
                        setScraped({ ...scraped, VideoLink: e.target.value })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.VideoLink
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                    />
                  </div>
                </div>
                <div className="flex flex-row mt-4">
                  <div className="flex flex-row w-1/2">
                    <div className=" font-medium mr-3 my-auto w-48">
                      Miniature Winners Club:
                    </div>
                    <input
                      placeholder="Miniature Winners Club"
                      value={scraped.Miniature}
                      onChange={(e) =>
                        setScraped({ ...scraped, Miniature: e.target.value })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.Miniature
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                    />
                  </div>
                  <div className="flex flex-row w-1/2">
                    <div className="ml-6 font-medium mr-3 my-auto w-48">
                      Price:
                    </div>
                    <input
                      placeholder="Price"
                      value={
                        scraped.originalPrice.max || scraped.salePrice?.max
                      }
                      onChange={(e) =>
                        setScraped({
                          ...scraped,
                          originalPrice: {
                            ...scraped?.originalPrice,
                            max: e.target.value,
                          },
                        })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.originalPrice.max
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                    />
                  </div>
                </div>
                <div className="flex flex-row mt-4">
                  <div className="flex flex-row w-1/2">
                    <div className="font-bold mr-3 my-auto w-48">
                      Lien Amazon:
                    </div>
                    <input
                      placeholder="Lien Amazon"
                      value={scraped.Lien_Amazon}
                      onChange={(e) =>
                        setScraped({
                          ...scraped,
                          Lien_Amazon: e.target.value,
                        })
                      }
                      className={`h-10 px-2 w-full border-green-400 border outline-none my-auto  rounded-lg`}
                    />
                  </div>
                  <div className="flex flex-row w-1/2">
                    <div className="ml-6 font-bold mr-3 my-auto w-48">
                      Lien Concurrent:
                    </div>
                    <input
                      placeholder="Lien Concurrent"
                      value={scraped.Lien_Concurrent}
                      onChange={(e) =>
                        setScraped({
                          ...scraped,
                          Lien_Concurrent: e.target.value,
                        })
                      }
                      className={`h-10 px-2 w-full border-green-400 border outline-none my-auto  rounded-lg`}
                    />
                  </div>
                  <div className="flex flex-row w-1/2">
                    <div className="ml-6 font-bold mr-3 my-auto w-48">
                      Lien agent Enfin Libre:
                    </div>
                    <input
                      placeholder="Lien agent Enfin Libre"
                      value={scraped.Lien_AgentEnfinLibre}
                      onChange={(e) =>
                        setScraped({
                          ...scraped,
                          Lien_AgentEnfinLibre: e.target.value,
                        })
                      }
                      className={`h-10 px-2 w-full border-green-400 border outline-none my-auto  rounded-lg`}
                    />
                  </div>
                </div>
                <div className="flex flex-row mt-4">
                  <div className="flex flex-row w-1/2">
                    <div className="font-medium mr-3 my-auto w-48">
                      Saisir des keywords:
                    </div>
                    <CreatableSelect
                      className={` w-full outline-none h-10  ${
                        keywords
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                      isMulti
                      placeholder="Keywords"
                      value={keywords}
                      onChange={(e) => {
                        if (e.length > 0) {
                          setKeywords([
                            ...e.filter((el, ind) => ind < e.length - 1),
                            ...e[e.length - 1].value.split(", ").map((i) => {
                              return { label: i, value: i };
                            }),
                          ]);
                        } else {
                          setKeywords(e);
                        }
                      }}
                      options={[]}
                    />
                  </div>

                  <div className="flex flex-row w-1/2">
                    <div className="ml-6 font-bold mr-3 my-auto w-48">
                      Lien Gif:
                    </div>
                    <input
                      placeholder="Lien Concurrent"
                      value={scraped.Lien_Gif}
                      onChange={(e) =>
                        setScraped({
                          ...scraped,
                          Lien_Gif: e.target.value,
                        })
                      }
                      className={`h-10 px-2 w-full border-green-400 border outline-none my-auto  rounded-lg`}
                    />
                  </div>
                </div>

                <div className="flex flex-row mt-4">
                  <div className="flex flex-row w-1/2">
                    <div className="mr-3 my-auto font-bold w-48">Visible</div>
                    <div className="w-full flex align-middle">
                      <input
                        type="checkbox"
                        className={` h-6 w-6 outline-none  ${
                          scraped.VisibleAllTime
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg my-auto`}
                        value={scraped.VisibleAllTime}
                        onChange={(e) =>
                          setScraped({
                            ...scraped,
                            VisibleAllTime: e.target.checked,
                          })
                        }
                        options={[]}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row mt-7 flex-wrap font-semibold text-lg  ">
                  Choisir une/ ou plusieurs photos
                </div>
                <div className="flex flex-row mt-4 flex-wrap justify-between ">
                  {scraped.images.map((i) => (
                    <div
                      onClick={(e) => {
                        if (selectedImages.includes(i)) {
                          setselectedImages(
                            selectedImages.filter(
                              (v, index) => index !== selectedImages.indexOf(i)
                            )
                          );
                        } else {
                          setselectedImages([...selectedImages, i]);
                        }
                      }}
                      className={`${
                        selectedImages.includes(i) ? "bg-gray-100" : ""
                      } shadow-lg  h-80 w-80 border rounded-lg border-gray-200  mt-4 mb-2 pb-7`}
                    >
                      <div className="px-9 pb-9 pt-6 h-full">
                        <img src={i} className="select-none" alt="img" />
                        <div className="w-full flex flex-row justify-center mt-4">
                          <input
                            checked={selectedImages.includes(i)}
                            className="mx-auto h-4 w-4"
                            type="checkbox"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* {scraped.ready && <div>{JSON.stringify(scraped)}</div>} */}
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            color="red"
            buttonType="link"
            onClick={(e) => {
              setselectedImages([]);
              setScraped({ loading: false, ready: false });
              setShowModal(false);
            }}
            ripple="dark"
          >
            Annuler
          </Button>
          {/* Update Visibility On Creation */}
          <Button
            color="green"
            onClick={(e) => {
              axios
                .post(
                  `${BACKEND_URL}/api/v1/product`,
                  {
                    Produit: scraped.title,
                    Lien_Aliexpress: scraped?.url,
                    Lien_Amazon: scraped.Lien_Amazon,
                    Lien_Concurrent: scraped.Lien_Concurrent,
                    Lien_AgentEnfinLibre: scraped.Lien_AgentEnfinLibre,
                    Lien_Gif: scraped.Lien_Gif,
                    Price: scraped.salePrice?.max || scraped.originalPrice.max,
                    Niche: scraped.Niche,
                    NOMBRE_DE_COMMANDES: scraped.orders,
                    Nombre_avis_client: scraped.ratings.totalStartCount,
                    Note: scraped.ratings.averageStar,
                    Lien_Publicité: scraped.AdLink,
                    Video_Winners_Club: scraped.VideoLink,
                    Miniature_Winners_Club: scraped.Miniature,
                    Keywords: JSON.stringify(keywords),
                    Images: JSON.stringify(selectedImages),
                    VisibleAllTime: scraped?.VisibleAllTime || false,
                  },
                  {
                    headers: {
                      authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  refresh();
                  toast.success("🥳 un produit a été crée ");
                  setShowModal(false);
                  setScraped({ loading: false, ready: false });
                  setselectedImages([]);
                  setKeywords([]);
                  setUrl("");
                })
                .catch((err) => {
                  toast.error("😕 une erreur est survenue ");
                  console.log(err);
                });
            }}
            ripple="light"
          >
            Créer
          </Button>
        </DialogFooter>
      </Modal>
      <Modal
        size="lg"
        active={showEditModal}
        toggler={() => setShowEditModal(false)}
      >
        <DialogHeader toggler={() => setShowEditModal(false)}></DialogHeader>
        <DialogBody>
          <div className="flex flex-row w-full flex-wrap ">
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6  font-medium mr-3 my-auto w-80">
                Nom du produit:
              </div>
              <textarea
                placeholder="Nom du produit"
                value={selectedproduct.Produit}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Produit: e.target.value,
                  })
                }
                className={`p-1 w-full outline-none  ${
                  selectedproduct.Produit
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                } rounded-lg`}
                rows="5"
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6  font-medium mr-3 my-auto w-80">
                Nom en anglais:
              </div>
              <textarea
                placeholder="Nom en anglais"
                value={selectedproduct.productNameEn}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    productNameEn: e.target.value,
                  })
                }
                className={`p-1 w-full outline-none  ${
                  selectedproduct.productNameEn
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                } rounded-lg`}
                rows="5"
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="  font-medium mr-3 my-auto w-48">
                choisir une niche
              </div>
              <Select
                className={` basic-single w-full h-10 my-auto ${
                  selectedproduct.Niche
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }`}
                classNamePrefix="select"
                value={{
                  label: selectedproduct.Niche || "",
                  value: selectedproduct.Niche || "",
                }}
                onChange={(e) =>
                  setselectedproduct({ ...selectedproduct, Niche: e.value })
                }
                isClearable={false}
                isSearchable={false}
                name="color"
                options={props.Category.map((i) => {
                  return { label: i.name, value: i.name };
                })}
              />
            </div>
            <div className="flex flex-row w-1/2  mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Lien Agent Enfin Libre:
              </div>
              <input
                placeholder="Lien AgentEnfinLibre"
                value={selectedproduct.Lien_AgentEnfinLibre}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Lien_AgentEnfinLibre: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Lien_AgentEnfinLibre
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2  mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Lien AliExpress:
              </div>
              <input
                placeholder="Lien AliExpress"
                value={selectedproduct.Lien_Aliexpress}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Lien_Aliexpress: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Lien_Aliexpress
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2  mt-5">
              <div className="ml-6 font-bold mr-3 my-auto w-80">
                Lien Amazon:
              </div>
              <input
                placeholder="Lien Amazon"
                value={selectedproduct.Lien_Amazon}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Lien_Amazon: e.target.value,
                  })
                }
                className={`p-1 w-full border-green-400 border outline-none my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2  mt-5">
              <div className="ml-6 font-bold mr-3 my-auto w-80">
                Lien Concurrent:
              </div>
              <input
                placeholder="Lien Concurrent"
                value={selectedproduct.Lien_Concurrent}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Lien_Concurrent: e.target.value,
                  })
                }
                className={`p-1 w-full border-green-400 border outline-none my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Nombre de commandes:
              </div>
              <input
                placeholder="Nombre de commandes"
                value={selectedproduct.NOMBRE_DE_COMMANDES}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    NOMBRE_DE_COMMANDES: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.NOMBRE_DE_COMMANDES
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none  my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">Prix:</div>
              <input
                type="number"
                placeholder="Prix"
                value={selectedproduct.Price}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Price: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Price
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none  my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Nombre avis client:
              </div>
              <input
                type="number"
                placeholder="Nombre de commandes"
                value={selectedproduct.Nombre_avis_client}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Nombre_avis_client: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Nombre_avis_client
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none  my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">Note:</div>
              <input
                type="number"
                placeholder="Note"
                value={selectedproduct.Note}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Note: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Note
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none  my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Lien Publicité:
              </div>
              <input
                placeholder="Lien Publicité"
                value={selectedproduct.Lien_Publicité}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Lien_Publicité: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Lien_Publicité
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none  my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Video Winners Club:
              </div>
              <input
                placeholder="Video Winners Club"
                value={selectedproduct.Video_Winners_Club}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Video_Winners_Club: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Video_Winners_Club
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Miniature Winners Club:
              </div>
              <input
                placeholder="Miniature Winners Club"
                value={selectedproduct.Miniature_Winners_Club}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Miniature_Winners_Club: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Miniature_Winners_Club
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none  my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Lien GIF:
              </div>
              <input
                placeholder="lien GIF"
                value={selectedproduct.Lien_Gif}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Lien_Gif: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Lien_Gif
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none  my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Audience Facebook
              </div>
              <CreatableSelect
                className={`w-full ${
                  (() => {
                    try {
                      return JSON.parse(selectedproduct.Keywords);
                    } catch {
                      return selectedproduct.Keywords;
                    }
                  })()
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }   rounded-lg`}
                isMulti
                disabled
                placeholder="Keywords"
                value={(() => {
                  try {
                    return JSON.parse(selectedproduct.Keywords);
                  } catch {
                    return selectedproduct.Keywords;
                  }
                })()}
                onChange={(e) => {
                  setselectedproduct({
                    ...selectedproduct,
                    Keywords:
                      e.length > 0
                        ? [
                            ...e.filter((el, ind) => ind < e.length - 1),
                            ...e[e.length - 1].value.split(", ").map((i) => {
                              return { label: i, value: i };
                            }),
                          ]
                        : e,
                  });
                }}
                options={[]}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Audience Facebook Anglais
              </div>
              <CreatableSelect
                className={`w-full ${
                  (() => {
                    try {
                      return JSON.parse(selectedproduct.Keywords_EN);
                    } catch {
                      return selectedproduct.Keywords_EN;
                    }
                  })()
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }   rounded-lg`}
                isMulti
                disabled
                placeholder="Keywords"
                value={(() => {
                  try {
                    return JSON.parse(selectedproduct.Keywords_EN);
                  } catch {
                    return selectedproduct.Keywords_EN;
                  }
                })()}
                onChange={(e) => {
                  setselectedproduct({
                    ...selectedproduct,
                    Keywords_EN:
                      e.length > 0
                        ? [
                            ...e.filter((el, ind) => ind < e.length - 1),
                            ...e[e.length - 1].value.split(", ").map((i) => {
                              return { label: i, value: i };
                            }),
                          ]
                        : e,
                  });
                }}
                options={[]}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-bold mr-3 my-auto w-80">En avant:</div>
              <div className="flex justify-start w-full">
                <input
                  type="checkbox"
                  checked={selectedproduct.featured}
                  onChange={(e) =>
                    setselectedproduct({
                      ...selectedproduct,
                      featured: e.target.checked,
                    })
                  }
                  className={`h-6 w-6 outline-none rounded-lg my-auto`}
                />
              </div>
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-bold mr-3 my-auto w-80">Visible</div>
              <div className="flex justify-start w-full">
                <input
                  type="checkbox"
                  checked={selectedproduct.VisibleAllTime}
                  onChange={(e) =>
                    setselectedproduct({
                      ...selectedproduct,
                      VisibleAllTime: e.target.checked,
                    })
                  }
                  className={` h-6 w-6 outline-none  ${
                    selectedproduct.VisibleAllTime
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg my-auto`}
                />
              </div>
            </div>
            <div className="flex flex-col w-full Images mt-5">
              <div className="flex justify-start items-center  w-full ml-6 font-medium mr-3 my-auto w-80">
                <h3 className="mt-8 ml-0">Images: &nbsp;&nbsp;&#40;</h3>
                <input
                  className="h-4 w-4 star ml-1 star-selected"
                  type="checkbox"
                  disabled
                />
                <h3 className="mt-8 ml-5">A la une &#41; &nbsp;&nbsp;&#40; </h3>

                <input
                  className="h-4 w-4 heart ml-1 heart-selected"
                  type="checkbox"
                  disabled
                />
                <h3 className="mt-8 ml-5">Carrousel&#41; &nbsp;&nbsp;&#40;</h3>
                <input
                  className="h-4 w-4 ml-1 heart"
                  type="checkbox"
                  disabled
                />
                <h3 className="mt-8 ml-5">Fiche Produit&#41; </h3>
              </div>
              {selectedproduct?.Images?.length > 0 && (
                <div className="w-full flex flex-row flex-wrap justify-start space-x-8">
                  {" "}
                  {[
                    ...JSON.parse(selectedproduct?.Images),
                    { type: "upload" },
                  ]?.map((i, index) => {
                    if (i.type !== "upload")
                      return (
                        <div
                          className={` shadow-lg  h-80 w-80 border rounded-lg border-gray-200  mt-4 mb-2 pb-7`}
                        >
                          <div className="px-9 pb-9 pt-6">
                            <div className="image-container h-60">
                              <img
                                src={i?.url}
                                className="select-none max-h-60 max-w-full mx-auto"
                                alt="img"
                              />
                            </div>
                            <div className="w-full flex flex-row justify-center mt-1">
                              <input
                                checked={selectedImages.some(
                                  (item) => item?.url === i?.url
                                )}
                                className="mx-auto h-4 w-4 mt-4"
                                type="checkbox"
                                onClick={() => {
                                  if (
                                    selectedImages.some(
                                      (item) => item?.url === i?.url
                                    )
                                  ) {
                                    const selected = selectedImages.filter(
                                      (v, index) =>
                                        index !==
                                        selectedImages.findIndex(
                                          (sImg) => sImg?.url === i?.url
                                        )
                                    );
                                    setselectedImages(selected);
                                    setChecked(selected[0]);
                                  } else {
                                    setselectedImages([...selectedImages, i]);
                                  }
                                }}
                              />
                              <input
                                className="mx-auto h-4 w-4 star"
                                type="checkbox"
                                checked={checked?.url === i?.url}
                                onClick={() => {
                                  if (checked?.url === i?.url) return;
                                  setChecked(i);
                                  if (
                                    selectedImages.some(
                                      (item) => item?.url === i?.url
                                    )
                                  ) {
                                    //swap position to 0
                                    const selected = selectedImages;
                                    const fromIndex = selected.findIndex(
                                      (sImg) => sImg?.url === i?.url
                                    );
                                    const toIndex = 0;
                                    const element = selected.splice(
                                      fromIndex,
                                      1
                                    )[0];
                                    selected.splice(toIndex, 0, element);
                                    setselectedImages([...selected]);
                                  } else {
                                    setselectedImages([i, ...selectedImages]);
                                  }
                                }}
                              />
                              <input
                                className="mx-auto h-4 w-4 heart"
                                type="checkbox"
                                checked={carouselImages.some(
                                  (item) => item?.url === i?.url
                                )}
                                onClick={() => {
                                  let selectedCarousel = carouselImages;
                                  const isCarousel = selectedCarousel.find(
                                    (item) => item?.url === i?.url
                                  );

                                  if (isCarousel) {
                                    selectedCarousel = selectedCarousel.filter(
                                      (item) => item?.url !== i?.url
                                    );
                                  } else {
                                    selectedCarousel = [...selectedCarousel, i];
                                  }
                                  setselectedImages(
                                    selectedImages.map((item) => ({
                                      url: item?.url,
                                      isCarousel: selectedCarousel.some(
                                        (itm) => itm?.url === item?.url
                                      ),
                                    }))
                                  );
                                  setCarouselImages(selectedCarousel);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    return (
                      <div class=" shadow-lg  h-80 w-80 border rounded-lg border-gray-200  mt-4 mb-2 pb-7 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                        <label
                          for="dropzone-file"
                          class="flex flex-col items-center justify-center w-full h-full"
                        >
                          <div class="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span class="font-semibold">Click to upload</span>{" "}
                              one or multiple images
                            </p>
                            <p class="text-xs text-gray-500 dark:text-gray-400">
                              SVG, PNG, JPG or GIF (MAX. 800x400px)
                            </p>
                          </div>
                          <input
                            id="dropzone-file"
                            type="file"
                            class="hidden"
                            accept="image/*"
                            multiple
                            onChange={(e) => handleFileUpload(e)}
                          />
                        </label>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            color="red"
            buttonType="link"
            onClick={(e) =>
              axios
                .post(
                  `${BACKEND_URL}/api/v1/productdelete`,
                  {
                    ...selectedproduct,
                  },
                  {
                    headers: {
                      authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  refresh();
                  toast.success("🥳 un produit a été supprimé");
                  setShowEditModal(false);
                  setselectedproduct({});
                })
                .catch((err) => {
                  toast.error("😕 une erreur est survenue ");
                  console.log(err);
                })
            }
            ripple="dark"
          >
            Supprimer
          </Button>

          <Button
            color="green"
            onClick={(e) => {
              axios
                .patch(
                  `${BACKEND_URL}/api/v1/product`,
                  {
                    ...selectedproduct,
                    Keywords: (() => {
                      try {
                        if (typeof selectedproduct.Keywords !== "string") {
                          return JSON.stringify(selectedproduct.Keywords);
                        } else {
                          return selectedproduct.Keywords;
                        }
                      } catch (err) {
                        return selectedproduct.Keywords;
                      }
                    })(),
                    Keywords_EN: (() => {
                      try {
                        if (typeof selectedproduct.Keywords_EN !== "string") {
                          return JSON.stringify(selectedproduct.Keywords_EN);
                        } else {
                          return selectedproduct.Keywords_EN;
                        }
                      } catch (err) {
                        return selectedproduct.Keywords_EN;
                      }
                    })(),
                    Images: JSON.stringify(selectedImages),
                  },
                  {
                    headers: {
                      authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  refresh();
                  toast.success("🥳 un produit a été mis à jour");
                  setShowEditModal(false);
                  setselectedproduct({});
                  setselectedImages([]);
                  /* setScraped({ loading: false, ready: false })
                                    setselectedImages([])
                                    setKeywords([])
                                    setUrl('') */
                })
                .catch((err) => {
                  toast.error("😕 une erreur est survenue ");
                  console.log(err);
                });
            }}
            ripple="light"
          >
            Sauvegarder
          </Button>
        </DialogFooter>
      </Modal>
      <Modal
        size="lg"
        active={showBulkModal}
        toggler={() => {
          setselectedImages([]);
          setScraped({ loading: false, ready: false });
          setShowBulkModal(false);
        }}
      >
        <DialogHeader
          toggler={() => {
            setselectedImages([]);
            setScraped({ loading: false, ready: false });
            setShowBulkModal(false);
          }}
        ></DialogHeader>
        <DialogBody>
          {csvData.length === 0 && (
            <>
              <h5>Click and Drag Upload</h5>
              <CSVReader
                onDrop={async (data) => {
                  const l = data
                    .slice(1, data.length)
                    .filter((i) => i.data.length > 3);
                  setCsvData(l);

                  var c = 0;
                  for (const line of l) {
                    if (line.data.length > 1) {
                      try {
                        // const test = await axios.post(
                        //   `${SCRAPPE_API_URL}/api/v1/scrapeproduct`,
                        //   {
                        //     url: line.data[1],
                        //   },
                        //   {
                        //     headers: {
                        //       authorization: `Bearer ${token}`,
                        //     },
                        //   }
                        // );

                        c += 1;

                        if (c === 0 && line) {
                          setThisrow({
                            Lien_Aliexpress: line.data[3],
                            Lien_AgentEnfinLibre: line.data[14],
                            Niche: line.data[12],
                            Produit: line.data[2],
                            Lien_Amazon: line.data[1],
                            Lien_Concurrent: line.data[0],
                            Price: line.data[4],
                            NOMBRE_DE_COMMANDES: line.data[7],
                            Nombre_avis_client: line.data[6],
                            selectedImages: [],
                            Images: [],
                            Note: line.data[5],
                            Lien_Publicité: line.data[8],
                            Keywords:
                              line.data[11].length > 0
                                ? line.data[11].split(", ").map((i) => {
                                    return { label: i, value: i };
                                  })
                                : [],
                            Video_Winners_Club: line.data[9],
                            Miniature_Winners_Club: line.data[10],
                            VisibleAllTime: false,
                            VisibleThisMonth: false,
                            VisibleNextMonth: true,
                            Lien_Gif: line.data[13] || "",
                          });
                        }
                        if (line)
                          setScarpedBulk((old) => [
                            ...old,
                            {
                              Lien_Aliexpress: line.data[3],
                              Lien_AgentEnfinLibre: line.data[14],
                              Niche: line.data[12],
                              Produit: line.data[2],
                              Lien_Amazon: line.data[1],
                              Lien_Concurrent: line.data[0],
                              Price: line.data[4],
                              NOMBRE_DE_COMMANDES: line.data[7],
                              Nombre_avis_client: line.data[6],
                              selectedImages: [],
                              Images: [],
                              Note: line.data[5],
                              Lien_Publicité: line.data[8],
                              Keywords:
                                line.data[11].length > 0
                                  ? line.data[11].split(", ").map((i) => {
                                      return { label: i, value: i };
                                    })
                                  : [],
                              Video_Winners_Club: line.data[9],
                              Miniature_Winners_Club: line.data[10],
                              VisibleAllTime: false,
                              VisibleThisMonth: false,
                              VisibleNextMonth: true,
                              Lien_Gif: line.data[13] || "",
                            },
                          ]);
                        else {
                          setScarpedBulkErrors((old) => [
                            ...old,
                            line?.data?.length && line?.data[1],
                          ]);
                        }
                      } catch (err) {
                        setScarpedBulkErrors((old) => [
                          ...old,
                          line?.data?.length && line?.data[1],
                        ]);
                      }
                    }
                  }
                }}
                onError={(err, file, inputElem, reason) => {}}
                addRemoveButton
                onRemoveFile={(data) => {}}
              >
                <span>Drop CSV file here or click to upload.</span>
              </CSVReader>
            </>
          )}
          {csvData.length > 0 && (
            <div className="w-full flex flex-col mb-20">
              <div className="w-full text-medium font-semibold">
                {`${csvData.length} produits à scrapper`}
              </div>
              <div className="w-full text-medium font-semibold">{`${scarpedBulk.length}/${csvData.length} traités`}</div>
              <div className="w-full text-medium font-semibold">
                <Select
                  className="basic-single w-full"
                  classNamePrefix="select"
                  value={{
                    label: selectedIndex,
                    value: selectedIndex,
                  }}
                  onChange={(e) => {
                    const aux = scarpedBulk;
                    aux[selectedIndex] = thisrow;
                    setScarpedBulk(aux);
                    setSelectedIndex(e.value);

                    setThisrow(scarpedBulk[e.value]);
                  }}
                  isClearable={false}
                  isSearchable={false}
                  name="color"
                  options={scarpedBulk.map((i, ind) => {
                    return { label: ind, value: ind };
                  })}
                />
              </div>

              <div className="w-full">
                {scarpedBulk
                  .filter((row, ind) => ind === selectedIndex)
                  .map((row, ind) => (
                    <div className="w-full flex flex-col">
                      <div className="flex flex-row w-1/2 mt-5">
                        <div className="  font-medium mr-3 my-auto w-48 mb-9">
                          choisir une niche
                        </div>
                        <Select
                          className={` basic-single w-full h-10 my-auto ${
                            thisrow.Niche
                              ? "border-green-400 border "
                              : "border-red-400 border-2 "
                          }`}
                          classNamePrefix="select"
                          value={{
                            label: thisrow.Niche || "",
                            value: thisrow.Niche || "",
                          }}
                          onChange={(e) => {
                            setThisrow({ ...thisrow, Niche: e.value });
                          }}
                          isClearable={false}
                          isSearchable={false}
                          name="color"
                          options={props.Category.map((i) => {
                            return { label: i.name, value: i.name };
                          })}
                        />
                      </div>
                      <div className="flex flex-row">
                        <div className="flex flex-row w-1/2">
                          <div className="font-medium mr-3 my-auto w-48">
                            Nom du produit:
                          </div>
                          <textarea
                            value={thisrow.Produit}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Produit: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Produit
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                            rows="5"
                          />
                        </div>
                        <div className="flex flex-row w-1/2">
                          <div className="font-medium mr-3 my-auto w-48">
                            Nom en anglais:
                          </div>
                          <textarea
                            value={thisrow.productNameEn}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                productNameEn: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.productNameEn
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                            rows="5"
                          />
                        </div>
                        <div className="flex flex-row w-1/2">
                          <div className="ml-6 font-medium mr-3 my-auto w-80">
                            Nombre de commandes:
                          </div>
                          <input
                            placeholder="Nom"
                            value={thisrow.NOMBRE_DE_COMMANDES}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                NOMBRE_DE_COMMANDES: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.NOMBRE_DE_COMMANDES
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row mt-4">
                        <div className="flex flex-row w-1/2">
                          <div className=" font-medium mr-3 my-auto w-48">
                            Nombre d'avis:
                          </div>
                          <input
                            placeholder="Nombre d'avis"
                            value={thisrow.Nombre_avis_client}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Nombre_avis_client: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Nombre_avis_client
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                        <div className="flex flex-row w-1/2 ">
                          <div className="ml-6  font-medium mr-3 my-auto w-48">
                            Note:
                          </div>
                          <input
                            placeholder="Note:"
                            value={thisrow.Note}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Note: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Note
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row mt-4">
                        <div className="flex flex-row w-1/2">
                          <div className=" font-bold mr-3 my-auto w-48">
                            Lien Amazon:
                          </div>
                          <input
                            placeholder="Lien publicité"
                            value={thisrow.Lien_Amazon}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Lien_Amazon: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Lien_Amazon
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                        <div className="flex flex-row w-1/2">
                          <div className="ml-6 font-bold mr-3 my-auto w-48">
                            Lien Concurrent:
                          </div>
                          <input
                            placeholder="Lien publicité"
                            value={thisrow.Lien_Concurrent}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Lien_Concurrent: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Lien_Concurrent
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row mt-4">
                        <div className="flex flex-row w-1/2">
                          <div className=" font-medium mr-3 my-auto w-48">
                            Lien publicité:
                          </div>
                          <input
                            placeholder="Lien publicité"
                            value={thisrow.Lien_Publicité}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Lien_Publicité: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Lien_Publicité
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                        <div className="flex flex-row w-1/2">
                          <div className="ml-6  font-medium mr-3 my-auto w-48">
                            Video Winners Club:
                          </div>
                          <input
                            placeholder="Video Winners Club"
                            value={thisrow.Video_Winners_Club}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Video_Winners_Club: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Video_Winners_Club
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row mt-4">
                        <div className="flex flex-row w-1/2">
                          <div className=" font-medium mr-3 my-auto w-48">
                            Miniature Winners Club:
                          </div>
                          <input
                            placeholder="Miniature Winners Club"
                            value={thisrow.Miniature_Winners_Club}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Miniature_Winners_Club: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Miniature_Winners_Club
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                        <div className="flex flex-row w-1/2">
                          <div className="ml-6 font-medium mr-3 my-auto w-48">
                            Price:
                          </div>
                          <input
                            placeholder="Price"
                            value={thisrow.Price}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Price: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Price
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                        <div className="flex flex-row w-1/2">
                          <div className="ml-6 font-medium mr-3 my-auto w-48">
                            Lien Gif:
                          </div>
                          <input
                            placeholder="Lien Gif"
                            value={thisrow.Lien_Gif}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Lien_Gif: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Lien_Gif
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row mt-4">
                        <div className="flex flex-row w-1/2">
                          <div className="font-medium mr-3 my-auto w-48">
                            Saisir des keywords:
                          </div>
                          <CreatableSelect
                            className={` w-full outline-none  ${
                              thisrow.Keywords
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                            isMulti
                            placeholder="Keywords"
                            value={thisrow.Keywords}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Keywords:
                                  e.length > 0
                                    ? [
                                        ...e.filter(
                                          (el, ind) => ind < e.length - 1
                                        ),
                                        ...e[e.length - 1].value
                                          .split(", ")
                                          .map((i) => {
                                            return { label: i, value: i };
                                          }),
                                      ]
                                    : e,
                              });
                            }}
                            options={[]}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row mt-4">
                        <div className="flex flex-row w-1/2">
                          <div className="ml-6 font-bold mr-3 my-auto w-48">
                            Visible
                          </div>
                          <div className="w-full flex align-middle">
                            <input
                              type="checkbox"
                              className={` h-6 w-6 outline-none  ${
                                thisrow.VisibleAllTime
                                  ? "border-green-400 border "
                                  : "border-red-400 border-2 "
                              } rounded-lg my-auto`}
                              value={thisrow.VisibleAllTime}
                              checked={thisrow.VisibleAllTime}
                              onChange={(e) => {
                                setThisrow({
                                  ...thisrow,
                                  VisibleAllTime: e.target.checked,
                                });
                              }}
                              options={[]}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-row mt-7 flex-wrap font-semibold text-lg  ">
                        Choisir une/ ou plusieurs photos
                      </div>
                      <div className="flex flex-row mt-4 flex-wrap justify-between ">
                        {thisrow.Images.map((i, index) => (
                          <div
                            onClick={(e) => {
                              if (thisrow.selectedImages.includes(i)) {
                                setThisrow({
                                  ...thisrow,
                                  selectedImages: thisrow.selectedImages.filter(
                                    (v, index) =>
                                      index !==
                                      thisrow.selectedImages.indexOf(i)
                                  ),
                                });
                              } else {
                                setThisrow({
                                  ...thisrow,
                                  selectedImages: [
                                    ...thisrow.selectedImages,
                                    i,
                                  ],
                                });
                              }
                            }}
                            className={`${
                              thisrow.selectedImages.includes(i)
                                ? "bg-gray-100"
                                : ""
                            } shadow-lg  h-80 w-80 border rounded-lg border-gray-200  mt-4 mb-2 pb-7`}
                          >
                            <div className="px-9 pb-9 pt-6">
                              <img src={i} className="select-none" alt="img" />

                              <div className="w-full flex flex-row justify-center mt-4">
                                <input
                                  checked={thisrow.selectedImages.includes(i)}
                                  className="mx-auto h-4 w-4"
                                  type="checkbox"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
              {scarpedBulkError?.length ? (
                <>
                  <h2>
                    Erreurs dans les produits suivants. Merci de vérifier le
                    lien dans le document ou les entrer manuellement.
                  </h2>
                  <ul>
                    {scarpedBulkError.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </DialogBody>
        <DialogFooter>
          <Button
            color="red"
            buttonType="link"
            onClick={(e) => {
              setselectedImages([]);
              setScraped({ loading: false, ready: false });
              setShowModal(false);
              setCsvData([]);
              setScarpedBulk([]);
              setThisrow({});
              setSelectedIndex(-1);
              setShowBulkModal(false);
            }}
            ripple="dark"
          >
            Annuler
          </Button>

          <Button
            color="green"
            onClick={async (e) => {
              var aux = scarpedBulk;
              aux[selectedIndex] = thisrow;
              for (const line of aux) {
                const { selectedImages, ...payload } = line;
                await axios
                  .post(
                    `${BACKEND_URL}/api/v1/product`,
                    {
                      ...payload,
                      Keywords: JSON.stringify(payload.Keywords),
                      Images: JSON.stringify(selectedImages),
                    },
                    {
                      headers: {
                        authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then(() => {})
                  .catch((err) => {});
              }
              setCsvData([]);
              setScarpedBulk([]);
              setThisrow({});
              setSelectedIndex(-1);
              setShowBulkModal(false);
              toast.success("🥳 tous les produits ont été crées");
            }}
            ripple="light"
          >
            Créer
          </Button>
        </DialogFooter>
      </Modal>
      {exchangeRateModal && (
        <Modal
          onClose={() => setExchangeRateModal(false)}
          active={exchangeRateModal}
        >
          <span>{"Configuration du taux de change EUR => USD"}</span>
          <form>
            <SimpleInput
              label="Limite :"
              type="number"
              min="0"
              name="limit"
              value={exchangeRate}
              onChange={(e) => setExchangeRate(e.target.value)}
            />
            <div className="flex flex-rowtext-right gap-[24px] justify-end">
              <button
                type="submit"
                className=" text-blue-600  px-4 py-1 rounded mt-4 text-md"
                onClick={() => setExchangeRateModal(false)}
              >
                Annuler
              </button>
              <button
                type="submit"
                className=" bg-blue-600 text-white px-4 py-1 rounded mt-4 text-md"
                onClick={onSubmitExchangerate}
              >
                Enregistrer
              </button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
}
