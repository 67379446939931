import { Line } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import { BACKEND_URL } from "../../variables";
import axios from "axios";
import Datepicker from "../Statistics/DatePicker";
import Modes from "../Statistics/Modes";

const LineChart = () => {
  const token = localStorage.getItem("token");

  const [statistics, setStatistics] = useState({});
  const [selectedMode, setSelectedMode] = useState("month");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const keys = Object.keys(statistics || {});
  const titles = [
    "Inscriptions directes",
    "Inscriptions assistées par l'administrateur",
  ];
  const labels = Object.values(statistics || {})[0]?.map(
    (_, index) => index + 1
  );
  const colors = ["#2ECC7177", "#E7493C77"];
  const borders = ["#2ECC71", "#E7493C"];

  const datasets = keys?.map((label, idx) => ({
    label: titles[idx],
    data: statistics[label],
    backgroundColor: colors[idx],
    borderColor: borders[idx],
  }));

  const data = {
    labels,
    datasets,
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const handleModeSelect = (mode) => {
    if (mode !== selectedMode) {
      setSelectedMode(mode);
    }
  };

  useEffect(() => {
    const getUsersStatistics = async () => {
      try {
        const response = await axios.post(
          `${BACKEND_URL}/api/v1/userStatisticsByDate`,
          {
            mode: selectedMode,
            date: selectedDate,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );
        const { data } = response;
        setStatistics(data.data.statistics || []);
      } catch (err) {
        console.error(err);
      }
    };

    getUsersStatistics();
  }, [selectedDate, selectedMode]);

  console.log(keys);

  return (
    <div className="w-full mt-2 bg-white p-2">
      <div className="w-full flex items-center px-6 justify-between">
        <div className="w-1/3">
          <Modes handleModeSelect={handleModeSelect} />
        </div>
        <div className="w-1/3">
          <Datepicker handleDateSelect={handleDateSelect} />
        </div>
      </div>
      <Line
        className="p-4 "
        data={data}
        options={{
          aspectRatio: 5 / 3,
          layout: {
            padding: {
              top: 32,
              right: 16,
              bottom: 16,
              left: 8,
            },
          },
          elements: {
            line: {
              fill: false,
              tension: 0.4,
            },
          },
          responsive: true,
          skipNull: true,
          plugins: {
            datalabels: {
              backgroundColor: function (context) {
                return context.dataset.borderColor;
              },
              borderRadius: 4,
              color: "white",
              font: {
                weight: "bold",
              },
              formatter: Math.round,
              padding: 6,
            },
            legend: {
              position: "top",
            },
            title: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

export default LineChart;
