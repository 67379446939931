import axios from "axios";
import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { BACKEND_URL } from "../../variables";
import { CopyToClipboard } from "react-copy-to-clipboard";


import { Tooltip as ReactTooltip } from 'react-tooltip'
import moment from "moment";

function Coupon() {
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const [loadingPromoCodes, setLoadingPromoCodes] = useState(false);
  const [loadingGenrateCode, setLoadingGenrateCode] = useState(false);

  const [couponList, setCouponList] = useState([]);
  const [promocodeList, setPromocodeList] = useState([]);
  const [clickedItem, setClickedItem] = useState(undefined);
  const [clickedItem1, setClickedItem1] = useState(undefined);
  const [copied, setCopied] = useState({ value: "", copied: false });

  const token = window.localStorage.getItem("token");

  const getCouponsList = async () => {
    setLoadingCoupon(true);
    const data = await axios.get(`${BACKEND_URL}/get-coupon-ids`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    setLoadingCoupon(false);
    if (data && !data.data?.success) {
      toast.error("😕 une erreur est survenue ");
      return;
    }
    setCouponList(data.data.data.coupons);
  };

  const getPromoCodes = async (couponId, index) => {
    setClickedItem(index);
    setPromocodeList([]);
    setLoadingPromoCodes(true);
    const data = await axios.post(
      `${BACKEND_URL}/get-promo-code`,
      {
        couponId,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    setLoadingPromoCodes(false);
    if (data && !data.data?.success) {
      toast.error("😕 une erreur est survenue ");
      return;
    }
    setPromocodeList(data.data.data.promocode);
  };

  const generateNewPromoCode = async (couponId) => {
    setLoadingGenrateCode(true);
    const data = await axios.post(
      `${BACKEND_URL}/generate-promo-code`,
      {
        couponId,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    setLoadingGenrateCode(false);
    if (data && !data.data?.success) {
      toast.error("😕 une erreur est survenue ");
      return;
    }
    getPromoCodes(couponId, clickedItem);
  };

  const handleCopy = (index) => {
    setClickedItem1(index);
    setTimeout(() => {
      setClickedItem1(undefined);
    }, 300);
  };

  useEffect(() => {
    getCouponsList();
  }, []);

  return (
    <div className="overflow-y-scroll overflow-x-scroll w-full h-full">
      <Toaster position="top-right" reverseOrder={false} />
      <div class="w-full mx-auto px-4 sm:px-8">
        <div className="w-full pt-10">
          <div className=" text-3xl font-bold">Coupon</div>
          <div className="flex flex-row w-full mt-5">
            {loadingCoupon && <h1>Loading...</h1>}
            {!loadingCoupon &&
              couponList.length > 0 &&
              couponList.map((item, index) => (
                <div
                  onClick={() => getPromoCodes(item.id, index)}
                  key={index}
                  className={
                    clickedItem === index
                      ? "rounded ml-5 text-green-300 font-semibold border py-2 my-auto px-4 bg-white cursor-pointer"
                      : "rounded ml-5 text-white font-semibold border py-2 my-auto px-4 bg-green-300 cursor-pointer"
                  }
                >
                  {item.name}
                </div>
              ))}
          </div>
          {loadingPromoCodes && <h1 className="m-5">Loading...</h1>}
          {clickedItem !== undefined && !loadingPromoCodes && (
            <div className="flex flex-col m-5">
              {promocodeList.length === 0 && (
                <p className="ml-3">aucun coupon active disponible !</p>
              )}
              {promocodeList.length > 0 && (
                <table class="table-auto border-collapse w-1/2 shadow-md">
                  <thead>
                    <tr>
                      <th class="bg-blue-700 text-white w-auto">
                        Promo Code Active
                      </th>
                      <th class="bg-blue-700 text-white w-auto">
                        Date de création
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {promocodeList.map((item, index) => {
                      return (
                        <tr class="bg-blue-100 text-blue-900 text-center">
                          <td
                            class="p-1 flex justify-center align-center"
                            key={index}
                          >
                            <CopyToClipboard
                              text={item.code}
                              onCopy={() =>
                                setCopied({ ...copied, copied: true })
                              }
                              data-tip="Cliquer pour copier"
                            >
                              <p
                                className={
                                  clickedItem1 === index
                                    ? "w-min font-bold"
                                    : "w-min"
                                }
                                key={index}
                                onClick={() => {
                                  handleCopy(index);
                                }}
                              >
                                {item.code}
                              </p>
                            </CopyToClipboard>
                            <ReactTooltip backgroundColor="grey" />
                          </td>
                          <td class="p-1" key={index}>
                            {moment
                              .unix(item.created)
                              .format("MM/DD/YYYY hh:mm")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              <div className="flex">
                <div
                  onClick={() =>
                    generateNewPromoCode(couponList[clickedItem].id)
                  }
                  className="rounded w-max mt-3 text-white font-semibold border py-1 my-auto px-2 bg-blue-400 cursor-pointer"
                >
                  Générer nouveau code
                </div>
                {loadingGenrateCode && (
                  <p className="ml-3 my-4">generation du nouveau code ...</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Coupon;
