import React from "react";

import Layout from "./layout/Layout";
import Prompts from "./components/prompts/Prompts";
import EditPrompt from "./components/editPrompt/EditPrompt";

const PromptContainer = () => {
  return (
    <div className="w-full mx-auto ">
      <Layout>
        <Prompts />
        <EditPrompt />
      </Layout>
    </div>
  );
};

export default PromptContainer;
