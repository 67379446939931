const SelectInput = ({ options, ...props }) => {
  const { defaultValue, name, label, onChange } = props;

  return (
    <div className="w-full mb-3">
      <label htmlFor={name} className="block text-sm font-semibold mb-2">
        {label}
      </label>
      <select
        value={defaultValue}
        name={name}
        defaultValue={defaultValue}
        className="w-full border-2 px-4 py-3 bg-white rounded-md"
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option} value={option} className="capitalize">
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
