import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prompts: [],
  selectedPrompt: null,
  refreshList: false,
  gptModels: undefined,
};

const promptsSlice = createSlice({
  name: "prompts",
  initialState,
  reducers: {
    AddPrompt: (state, action) => {
      return {
        ...state,
        prompts: [...state.prompts, action.payload],
      };
    },
    UpdatePrompts: (state, action) => {
      return {
        ...state,
        prompts: action.payload,
      };
    },
    SetSelectedPrompt: (state, action) => {
      return {
        ...state,
        selectedPrompt: action.payload,
      };
    },
    RemoveSelectedPrompt: (state, action) => {
      return {
        ...state,
        selectedPrompt: null,
      };
    },
    RefreshList: (state, action) => {
      return {
        ...state,
        refreshList: action.payload,
      };
    },
    GptModels: (state, action) => {
      return {
        ...state,
        gptModels: action.payload,
      };
    },
  },
});

export const {
  AddPrompt,
  UpdatePrompts,
  SetSelectedPrompt,
  RefreshList,
  RemoveSelectedPrompt,
  GptModels,
} = promptsSlice.actions;
export default promptsSlice.reducer;
