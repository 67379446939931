import {
  RefreshList,
  SetSelectedPrompt,
  UpdatePrompts,
} from "../../store/slice/promptsSlice";
import { useDispatch, useSelector } from "react-redux";

import { FiTrash, FiEdit } from "react-icons/fi";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import IconGpt from "../../../../assets/gpt-icon.jpg";
import { AiTwotoneCalendar } from "react-icons/ai";

const PromptItem = ({ item, setLoading }) => {
  // Store
  const dispatch = useDispatch();
  const { prompts, gptModels } = useSelector((state) => state.prompts);

  const onRedirectEnd = () => {
    dispatch(SetSelectedPrompt({ ...item, group: item.group?._id }));
  };

  const onDeletePrompt = (id) => async () => {
    setLoading(true);
    try {
      await axios.delete(`api/v1/prompts/${id}`);
      const updatedPrompts = prompts.filter((prompt) => prompt._id !== id);
      dispatch(UpdatePrompts(updatedPrompts));
      toast.success("Prompt deleted successfully");
      dispatch(RefreshList(true));
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full flex items-center justify-between bg-white py-3 px-3 md:px-6 border-b-2">
      <div className="flex items-center flex-1">
        <img
          className="w-9 h-9 object-cover rounded-full mr-3"
          src={IconGpt}
          alt="chat gpt icon"
        />
        <div>
          <span className="text-gray-700 block -mb-1 font-semibold text-sm truncate w-24 lg:w-32">
            {item.label}
          </span>
        </div>
      </div>
      <span className="text-gray-700 block -mb-1 font-semibold text-sm truncate w-24 lg:w-32">
        {item.label_EN}
      </span>
      <span className="flex-1 hidden md:block text-start">{item.order}</span>
      <span className="flex-1 hidden md:block text-start">
        {gptModels?.find((a) => item?.model?.modelId === a._id)?.name ||
          "Not assigned"}
      </span>
      <span className="flex-1 hidden md:block text-start">
        {item.group?.name || "Not assigned"}
      </span>
      <div className="flex-1 text-sm lg:text-base text-gray-500 items-center hidden md:flex">
        <i className="mr-2">
          <AiTwotoneCalendar />
        </i>
        <span>{new Date(item.updatedAt).toLocaleString()}</span>
      </div>
      <div className="flex-1 flex items-center justify-end">
        <div>
          <span
            className={`px-2 py-1 rounded mr-3 ${
              item.published
                ? "text-green-500 bg-green-100"
                : "text-yellow-500 bg-yellow-100"
            }`}
          >
            {item.published ? "Publiée" : "Brouillon"}
          </span>
        </div>
        <span
          className=" content-end text-blue-600 underline mr-6 cursor-pointer"
          onClick={onRedirectEnd}
        >
          <FiEdit
            color="green"
            className="cursor-pointer"
            onClick={() => onRedirectEnd(item._id)}
          />
        </span>

        <div>
          <FiTrash
            color="red"
            className="cursor-pointer"
            onClick={onDeletePrompt(item._id)}
          />
        </div>
      </div>
    </div>
  );
};

export default PromptItem;
