import React, { useEffect, useState } from 'react';

import { BACKEND_URL } from '../../variables';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';

const UserSearch = ({ handleUserSelect }) => {
  const token = localStorage.getItem('token');

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState(null);

  const debounce = (func, delay) => {
    let debounceTimer;
    return (...args) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func(...args), delay);
    };
  };

  const handleSearch = debounce(async (query) => {
    if (query.length < 2) {
      setSearchResults([]);
      return;
    }

    setError(null);

    try {
      const response = await axios.post(
        `${BACKEND_URL}/api/v1/user/search`,
        {
          page: 0,
          user: query,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );
      setSearchResults(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
    }
  }, 500);

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery]);

  const onUserSelect = (user) => {
    handleUserSelect(user);
    setSearchQuery('');
    setSearchResults([]);
  };

  return (
    <div className="w-full relative">
      <input
        type="text"
        placeholder="Recherche d'utilisateur..."
        className="w-full rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      {searchResults.length > 0 && (
        <ul className="absolute top-0 mt-12 w-full bg-white left-0 max-h-96 overflow-auto">
          {searchResults.map((user) => (
            <li
              key={user.id}
              className="border-b border-gray-100 p-2 text-sm cursor-pointer hover:bg-gray-100 transition-colors duration-200"
              onClick={() => onUserSelect(user)}
            >
              {user.firstName} {user.lastName}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UserSearch;
