import { Pie } from "react-chartjs-2";

const PieChart = ({ statistics, registredUsers }) => {
  const percentage = statistics.map((usage) => ({
    value: usage,
  }));

  const data = {
    labels: [
      "active",
      "active_coupon",
      "past_due",
      "incomplete_expired",
      "incomplete",
      "canceled",
      "trialing",
      "unpaid",
    ],
    datasets: [
      {
        data: percentage.map((model) => model.value),
        backgroundColor: [
          "#2ECC7188",
          "#E74C3C88",
          "#F39C1288",
          "#F1C40F88",
          "#95A5A688",
          "#3498DB88",
          "#E67E2288",
        ],
        borderColor: [
          "#27AE60",
          "#C0392B",
          "#E67E22",
          "#F39C12",
          "#7F8C8D",
          "#2980B9",
          "#D35400",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="w-[40%] bg-white p-4 rounded-md">
      <div className="">
        <div className="p-2 text-center">
          <p className="text-md border-b-2  border-gray-50">
            <span className="font-semibold">
              Répartition des statuts d'abonnement des utilisateurs
            </span>
            <span className="block text-md">
              Utilisateurs totaux{" "}
              <span className="font-bold text-blue-600 text-lg">
                {registredUsers}
              </span>{" "}
            </span>
          </p>
        </div>
        <Pie
          options={{
            elements: {},
            responsive: true,
            plugins: {
              datalabels: {
                color: "#000",
                font: {
                  weight: "bold",
                },
                formatter: (value, context) => {
                  return value;
                },
              },
              legend: {
                position: "top",
              },
              title: {
                display: false,
              },
            },
          }}
          data={data}
          className="w-full p-8"
        />
      </div>
    </div>
  );
};

export default PieChart;
