import { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../variables";
import toast, { Toaster } from "react-hot-toast";
import { RotatingLines } from "react-loader-spinner";
import "./Assets.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import Modal from "react-modal";
import { DebounceInput } from "react-debounce-input";
import { DEBOUNCE_TYPING_DELAY } from "../../utils/contants";

const Assets = () => {
  const [assetList, setAssetList] = useState([]);
  const [filtredAssetList, setFiltredAssetList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchAssets = async () => {
    const errorMessage = "Erreur lors de la recuperation de la liste d'assets";

    try {
      setLoading(true);

      const res = await axios.get(`${BACKEND_URL}/api/v1/assets`, {
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });

      if (res?.data?.success && res?.data?.data) {
        setFiltredAssetList(res?.data?.data);
        setAssetList(res?.data?.data);
      } else {
        toast.error(errorMessage);
      }
    } catch (err) {
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    const errorMessage = "Échec de la suppression de l'image";
    const fileName = selectedImageUrl.split("/")
    try {
      const res = await axios.delete(
        `${BACKEND_URL}/api/v1/assets/${fileName[fileName.length -1]}`,
        {
          headers: {
            authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );

      if (res?.data?.success) {
        setAssetList((prevList) =>
          prevList.filter((url) => url !== selectedImageUrl)
        );
        setFiltredAssetList((prevList) =>
          prevList.filter((url) => url !== selectedImageUrl)
        );
        toast.success("Image supprimée avec succès");
      } else {
        toast.error(errorMessage);
      }
    } catch (err) {
      toast.error(errorMessage);
    } finally {
      setDeleteModalOpen(false);
      setSelectedImageUrl(null);
    }
  };

  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
    setSelectedImageUrl(null);
  };

  useEffect(() => {
    fetchAssets();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      setFiltredAssetList(
        assetList.filter((url) =>
        url.split("/")[url.split("/").length -1]
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFiltredAssetList(assetList);
    }
    setPaginationIndex(0);
  }, [searchQuery]);

  useEffect(() => {
    if (
      filtredAssetList.length &&
      paginationIndex > 10 &&
      paginationIndex >= filtredAssetList.length
    ) {
      setPaginationIndex(paginationIndex - 10);
    }
  }, [assetList, filtredAssetList, paginationIndex]);

  return (
    <div className="w-full m-4 flex flex-col justify-center items-center">
      <Toaster position="top-right" reverseOrder={false} />
      {loading && (
        <div className="m-10">
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      )}
      {!loading && (
        <div class="flex w-full m-4 ml-12 relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-4">
            <svg viewBox="0 0 24 24" class="h-4 w-4 fill-current text-gray-500">
              <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
            </svg>
          </span>
          <DebounceInput
            minLength={2}
            debounceTimeout={DEBOUNCE_TYPING_DELAY}
            placeholder="Recherche"
            onChange={(event) => {
              setSearchQuery(event.target.value);
            }}
            class="search appearance-none rounded border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none m-2"
          />
        </div>
      )}
      {!loading && filtredAssetList.length === 0 && (
        <div className="flex flex-wrap justify-center items-center">
          Aucun résultat
        </div>
      )}
      <div className="flex flex-wrap justify-center items-center gap-10">
        {!loading &&
          filtredAssetList.length > 0 &&
          filtredAssetList
            .slice(paginationIndex, paginationIndex + 10)
            .map((url) => {
              return (
                <div className="flex flex-col justify-center items-center assetCardWrapper">
                  <div
                    key={url}
                    className="assetCard border-2 rounded-lg flex justify-center items-center p-2 relative"
                  >
                    <img
                      key={url}
                      alt="lastpart"
                      src={url}
                      className="w-full h-full cursor-pointer"
                      onClick={() =>
                        window.open(url, "_blank", "noopener,noreferrer")
                      }
                    />
                    <RiDeleteBin6Line
                      size={25}
                      className="absolute -top-2 -right-2 text-red-500 cursor-pointer"
                      onClick={() => handleDeleteClick(url)}
                    />
                  </div>
                  <span className="break-all assetName">
                    {url.split("/")[url.split("/").length -1]}
                  </span>
                </div>
              );
            })}
        {isDeleteModalOpen && (
          <Modal
            isOpen={isDeleteModalOpen}
            onRequestClose={handleCancelDelete}
            contentLabel="Confirm Delete Modal"
            style={{
              content: {
                width: "300px",
                height: "400px",
                margin: "auto",
              },
            }}
          >
            <div className="flex flex-col items-center justify-between h-full">
              <span>Ete vous sur de vouloir supprimer cette image ?</span>
              <img
                key={selectedImageUrl}
                alt="lastpart"
                src={selectedImageUrl}
                width="150px"
                height="150px"
                className="m-2"
              />
              <div className="flex gap-2">
                <button
                  className="p-2 bg-red-500 rounded-xl"
                  onClick={handleConfirmDelete}
                >
                  Oui
                </button>
                <button
                  className="p-2 bg-green-500 rounded-xl"
                  onClick={handleCancelDelete}
                >
                  Non
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
      {!loading && filtredAssetList.length > 0 && (
        <div className="w-full flex justify-center items-center m-10 gap-4">
          <button
            className={`p-2 rounded-xl text-xl ${
              paginationIndex <= 0 ? "bg-gray-200" : "bg-yellow-300"
            } `}
            onClick={() => setPaginationIndex(paginationIndex - 10)}
            disabled={paginationIndex <= 0}
          >
            {"<"}
          </button>
          <span>
            {paginationIndex / 10 + 1} /{" "}
            {Math.ceil(filtredAssetList.length / 10)}
          </span>
          <button
            className={`p-2 rounded-xl text-xl ${
              paginationIndex + 10 >= filtredAssetList.length
                ? "bg-gray-200"
                : "bg-yellow-300"
            } `}
            onClick={() => setPaginationIndex(paginationIndex + 10)}
            disabled={paginationIndex + 10 >= filtredAssetList.length}
          >
            {">"}
          </button>
        </div>
      )}
    </div>
  );
};

export default Assets;
