export const getDate = (date, mode) => {
  let dateString = '';

  if (mode === 'day') {
    dateString = `le ${new Date(date).toLocaleDateString('fr', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    })}`;
  } else if (mode === 'month') {
    let startOfMonth = new Date(date);
    startOfMonth.setDate(1);
    let endOfMonth = new Date(date);
    endOfMonth.setMonth(endOfMonth.getMonth() + 1);
    endOfMonth.setDate(0);
    dateString = `en ${startOfMonth.toLocaleString('fr', {
      month: 'long',
    })} ${startOfMonth.getFullYear()}`;
  } else if (mode === 'year') {
    dateString = `en ${new Date(date).getFullYear()}`;
  }

  return dateString;
};

// Generate an array of random colors
export const generateRandomColors = (length) => {
  const colors = [];
  const borderColors = [];

  for (let i = 0; i < length; i++) {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    colors.push(`rgba(${r}, ${g}, ${b}, 0.2)`);
    borderColors.push(`rgba(${r}, ${g}, ${b}, 1)`);
  }

  return { colors, borderColors };
};
