import {
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import {
  genericPatchVisibility,
  genericVisibilityChange,
} from "../../utils/generic";
import toast, { Toaster } from "react-hot-toast";

import { BACKEND_URL } from "../../variables";
import Button from "./Button";
import { DEBOUNCE_TYPING_DELAY, UsersTableHeader } from "../../utils/contants";
import { DebounceInput } from "react-debounce-input";
import InfiniteScroll from "react-infinite-scroll-component";
import Modal from "./Modal";
import Select from "react-select";
import { StatusText } from "../../utils/contants";
import axios from "axios";
import { saveAs } from "file-saver";

export default function TableFactory(props) {
  const [Data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [end, setEnd] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [newItem, setNewItem] = useState({
    ...(["fournisseur", "Concurrent"].includes(props.Name)
      ? { VisibleThisMonth: true, VisibleNextMonth: true }
      : {}),
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [deleteFingerprints, setDeleteFingerprints] = useState(false);

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [selectedItem, setselectedItem] = useState({});
  const [key, setkey] = useState("test");
  const [search, setSearch] = useState("");
  const [Niche, setNiche] = useState("");
  const [active, setActive] = useState("");
  const [registredViaClient, setRegistredViaClient] = useState("");
  const [status, setStatus] = useState("");

  const token = window.localStorage.getItem("token");

  const [visibilityChanges, setVisibilityChanges] = useState({});

  const visibitilyQuery = {
    // VisibleThisMonth: active === "this" ? true : "",
    // VisibleNextMonth: active === "next" ? true : "",
    VisibleAllTime:
      active === "allTime" ? true : active === "NotVisible" ? false : "",
    // NotVisible: active === "NotVisible" ? true : "",
  };

  const renderNiche = () =>
    !["Video", "Calendar", "Tutorial", "adspy"].includes(props.Name);
  const handleVisibilityChange = (valueToChange, fullValueToChange, i) => {
    console.log({ i });
    genericVisibilityChange(
      props.Name,
      i,
      valueToChange,
      fullValueToChange,
      visibilityChanges,
      setVisibilityChanges,
      Data,
      setData
    );
  };

  const refresh = () => {
    axios
      .post(
        `${BACKEND_URL}/api/v1/${props.Name}/search`,
        {
          page: 0,
          [props.Name]: search,
          Niche: Niche,
          registredViaClient: registredViaClient,
          subscriptionStatus: status,
          ...visibitilyQuery,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPage(0);
        setData(data.data);
        if (props.Name === "Category") {
          window.localStorage.setItem("Category", JSON.stringify(data.data));
        }
      })
      .catch((err) => {
        setData([]);
      });
  };
  const fetchData = () => {
    axios
      .post(
        `${BACKEND_URL}/api/v1/${props.Name}/search`,
        {
          page: page,
          [props.Name]: search,
          Niche: Niche,
          registredViaClient: registredViaClient,
          subscriptionStatus: status,
          ...visibitilyQuery,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPage(page + 1);
        if (data.data.length === 0 || data.data.length < 20) setEnd(false);
        else if (!end) {
          setEnd(true);
        }
        setData([...Data, ...data.data]);
      })
      .catch((err) => {
        setData([]);
      });
  };
  useEffect(() => {
    setVisibilityChanges({});
    setNiche("");
    setSearch("");
    setActive("");
    axios
      .post(
        `${BACKEND_URL}/api/v1/${props.Name}/search`,
        {
          page: 0,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((data) => {
        setPage(page + 1);
        setData(data.data);
        data.data.filter((e) => {
          return Object.keys(e).map((k) => e[k]);
        });
      })
      .catch((err) => {
        setData([]);
      });
  }, [props]);

  useEffect(() => {
    fetchData();
  }, [Niche, active, search, registredViaClient, status]);
  const exportCSV = async (e) => {
    try {
      const data = await axios.post(
        `${BACKEND_URL}/api/v1/${props.Name}/export`,
        {
          [props.Name]: search,
          registredViaClient: registredViaClient,
          subscriptionStatus: status,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );

      const blob = new Blob([data.data], { type: "text/csv" });
      saveAs(blob, `${props.Name}-${new Date().toString()}.csv`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="overflow-y-scroll overflow-x-scroll w-full h-full">
      <Toaster position="top-right" reverseOrder={false} />
      <div class="w-full mx-auto px-4 sm:px-8">
        <div class="py-4">
          <div>
            <h2 class="text-2xl font-semibold leading-tight">{props.Names}</h2>
          </div>
          <div class="my-2 flex sm:flex-row flex-col justify-between">
            <div class="flex">
              {props.Name === "user" && (
                <div class="relative">
                  <select
                    value={registredViaClient}
                    onChange={(e) => {
                      setRegistredViaClient(e.target.value);
                      setStatus("");
                      setPage(0);
                      setData([]);
                    }}
                    class="h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value={""}>Tout les utilisateurs</option>
                    <option value={false}>Enregistrer via sheet/admin</option>
                    <option value={true}>Enregistrer via client</option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              )}
              {props.Name === "user" && registredViaClient === "true" && (
                <div class="relative">
                  <select
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                      setPage(0);
                      setData([]);
                    }}
                    class="h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value={""}>Tout les status</option>
                    <option value={"active"}>{StatusText.active}</option>
                    <option value={"active_coupon"}>
                      {StatusText.active_coupon}
                    </option>
                    <option value={"past_due"}>{StatusText.past_due}</option>
                    <option value={"unpaid"}>{StatusText.unpaid}</option>
                    <option value={"canceled"}>{StatusText.canceled}</option>
                    <option value={"trialing"}>{StatusText.trialing}</option>
                    <option value={"incomplete"}>
                      {StatusText.incomplete}
                    </option>
                    <option value={"incomplete_expired"}>
                      {StatusText.incomplete_expired}
                    </option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              )}
              {![
                "Category",
                "user",
                "admin",
                "VideoMarketing",
                "VideoMiniInflu",
                "VideoEbook",
                "testimony",
              ].includes(props.Name) && (
                <div class="flex flex-row mb-1 sm:mb-0">
                  {renderNiche() && (
                    <div class="relative">
                      <select
                        value={Niche}
                        onChange={(e) => {
                          setNiche(e.target.value);
                          setPage(0);
                          setData([]);
                        }}
                        class="h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value={""}>Toutes les niches</option>
                        {props.Category.map((cat) => (
                          <option value={cat.name}>{cat.name}</option>
                        ))}
                      </select>
                      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          class="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  )}
                  <div class="relative">
                    <select
                      value={active}
                      onChange={(e) => {
                        setActive(e.target.value);
                        setPage(0);
                        setData([]);
                      }}
                      class={`${
                        !renderNiche() ? "border rounded-l" : ""
                      } appearance-none h-full rounded-r border-t sm:rounded-r-none sm:border-r border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500`}
                    >
                      <option value={""}>Tout</option>
                      {/* <option value="this">Active ce mois</option>
                    <option value="next">Active le mois prochain</option> */}
                      <option value="allTime">Visible</option>
                      <option value="NotVisible">Non Visible</option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        class="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              )}
              <div class="block relative">
                <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                  <svg
                    viewBox="0 0 24 24"
                    class="h-4 w-4 fill-current text-gray-500"
                  >
                    <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                  </svg>
                </span>
                <DebounceInput
                  minLength={2}
                  debounceTimeout={DEBOUNCE_TYPING_DELAY}
                  onChange={(event) => {
                    setPage(0);
                    setData([]);
                    setSearch(event.target.value);
                  }}
                  class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                />
              </div>
            </div>

            <div class="flex flex-row justify-end gap-6 items-center">
              {Object.keys(visibilityChanges).length !== 0 && (
                <div
                  onClick={async () => {
                    setShowSaveModal(true);
                  }}
                  className="border rounded-lg px-3 py-1 cursor-pointer font-semibold hover:bg-yellow-200 m-auto"
                >
                  Enregistrer
                </div>
              )}
              {Object.keys(visibilityChanges).length !== 0 && (
                <div
                  onClick={async () => {
                    setVisibilityChanges({});
                    refresh();
                  }}
                  className="border rounded-lg px-3 py-1 cursor-pointer font-semibold hover:bg-yellow-200 m-auto"
                >
                  Rafraichir
                </div>
              )}
              {props.Name === "user" && (
                <div>
                  <button
                    onClick={exportCSV}
                    className="border rounded-lg px-3 py-2 cursor-pointer font-semibold  bg-green-500 text-white hover:bg-green-600 m-auto"
                  >
                    Exporter
                  </button>
                </div>
              )}
              <div>
                <button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className="border rounded-lg px-3 py-1 cursor-pointer font-semibold hover:bg-yellow-200"
                >
                  Créer
                </button>
              </div>
            </div>
          </div>

          <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <InfiniteScroll
                dataLength={Data.length} //This is important field to render the next data
                next={fetchData}
                hasMore={end}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                <table class="min-w-full leading-normal">
                  <thead>
                    <tr>
                      {props.columns
                        .filter((item) => !item.hide)
                        ?.map((i) => (
                          <th class="px-5 py-3 w-96 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            {i.text}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((i) => (
                      <tr
                        onDoubleClick={(e) => {
                          setselectedItem(i);
                          setShowEditModal(!showEditModal);
                        }}
                        className="group"
                      >
                        {props.columns.map((col) => {
                          if (col.db == "byPassCouponCheck") return <></>;
                          if (col.db == "createdAt" || col.db == "canceledAt")
                            return (
                              <td class="  group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-no-wrap">
                                  {i[col.db]
                                    ? new Date(i[col.db]).toDateString()
                                    : "En cours/Non defini"}
                                </p>
                              </td>
                            );

                          if (col.type === "bool") {
                            return (
                              <td class="  group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-no-wrap">
                                  {i[col.db] === true
                                    ? "Client"
                                    : "Sheet/Admin"}
                                </p>
                              </td>
                            );
                          }
                          if (col.type === "text-status") {
                            return (
                              <td class="  group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-no-wrap">
                                  {i[col.db] !== undefined &&
                                  col.type === "text" &&
                                  col.db === "Title" &&
                                  i[col.db].length > 30
                                    ? i[col.db].substr(0, 26) + "..."
                                    : StatusText[i[col.db]]}
                                </p>
                              </td>
                            );
                          }
                          if (col.type === "text" || col.type === "number") {
                            return (
                              <td class="  group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <p class="text-gray-900 whitespace-no-wrap">
                                  {i[col.db] !== undefined &&
                                  col.type === "text" &&
                                  col.db === "Title" &&
                                  i[col.db].length > 30
                                    ? i[col.db].substr(0, 26) + "..."
                                    : col.type === "number" && !i[col.db]
                                      ? "Non défini"
                                      : i[col.db]}
                                </p>
                              </td>
                            );
                          }
                          if (col.type === "checkbox") {
                            return (
                              <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 bg-blue-200 outline-none"
                                  checked={i[col.db]}
                                  disabled={
                                    i["VisibleAllTime"] &&
                                    col.db !== "VisibleAllTime"
                                  }
                                  onClick={() => {
                                    if (col.db === "VisibleAllTime") {
                                      handleVisibilityChange(
                                        "allTime",
                                        "VisibleAllTime",
                                        i
                                      );
                                      return;
                                    } else if (col.db === "VisibleThisMonth") {
                                      handleVisibilityChange(
                                        "thisMonth",
                                        "VisibleThisMonth",
                                        i
                                      );
                                      return;
                                    } else if (col.db === "VisibleNextMonth") {
                                      handleVisibilityChange(
                                        "nextMonth",
                                        "VisibleNextMonth",
                                        i
                                      );
                                    } else {
                                      return;
                                    }
                                  }}
                                />
                              </td>
                            );
                          }
                          if (col.type === "multiple") {
                            try {
                              return (
                                <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                  <input
                                    type="checkbox"
                                    className="h-4 w-4 bg-blue-200 outline-none"
                                    checked={i[col.db]}
                                    onClick={() => {
                                      if (col.db === "VisibleThisMonth") {
                                        handleVisibilityChange(
                                          "thisMonth",
                                          "VisibleThisMonth",
                                          i
                                        );
                                        return;
                                      } else if (
                                        col.db === "VisibleNextMonth"
                                      ) {
                                        handleVisibilityChange(
                                          "nextMonth",
                                          "VisibleNextMonth",
                                          i
                                        );
                                      } else {
                                        return;
                                      }
                                    }}
                                  />
                                </td>
                              );
                            } catch (err) {
                              return;
                            }
                          }
                          if (col.type === "multiple") {
                            try {
                              return (
                                <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                  <div className="flex flex-wrap flex-row w-full justify-start ">
                                    {i[col.db].map((j) => (
                                      <span class="mr-3 relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                        <span
                                          aria-hidden
                                          class="absolute inset-0 bg-green-200 opacity-50 rounded-full "
                                        ></span>
                                        <span class="relative">{j}</span>
                                      </span>
                                    ))}
                                  </div>
                                </td>
                              );
                            } catch (err) {
                              return;
                            }
                          }
                          if (col.type === "file" && !col.pdf) {
                            return (
                              <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <img
                                  src={`${i[col.db]}`}
                                  height="40"
                                  width="40"
                                />
                              </td>
                            );
                          }
                          if (col.pdf) {
                            return (
                              <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <a href={`${i[col.db]}`}>Lien pdf</a>
                              </td>
                            );
                          }
                          return (
                            <td class="group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                              <a
                                target={"_blank"}
                                className="underline text-blue-400"
                                href={i[col.db]}
                              >
                                {" "}
                                Link
                              </a>
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
              <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
                <span class="text-xs xs:text-sm text-gray-900">
                  Showing 1 to 4 of 50 Entries
                </span>
                <div class="inline-flex mt-2 xs:mt-0">
                  <button class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                    Prev
                  </button>
                  <button class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        active={showSaveModal}
        toggler={() => {
          setShowSaveModal(false);
        }}
      >
        <DialogHeader
          toggler={() => {
            setShowSaveModal(false);
          }}
        ></DialogHeader>
        <DialogBody>
          <div class="flex flex-col justify-center items-center">
            <h1 class="font-large text-xl pb-3">
              Veuillez confirmer les changements
            </h1>
            <table class="bg-white text-gray-900 table-auto border-separate w-full shadow-md">
              <thead>
                <tr>
                  <th class="bg-blue-700 text-white p-1 w-auto">
                    {props.Name}
                  </th>
                  <th class="bg-blue-700 text-white p-1">Visible</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(visibilityChanges).map((item, index) => {
                  return (
                    <tr key={index} class="bg-blue-100 text-blue-900">
                      <td class="p-1 flex flex-row justify-center items-center">
                        {visibilityChanges[item].name}
                      </td>
                      <td class="p-1">
                        <div class="flex flex-row justify-center items-center">
                          <input
                            type="checkbox"
                            id="old all time"
                            checked={visibilityChanges[item].old.allTime}
                          />
                          <p>&emsp;&rarr;&emsp;</p>
                          <input
                            type="checkbox"
                            id="new all time"
                            checked={visibilityChanges[item].new.allTime}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            color="red"
            buttonType="link"
            onClick={(e) => {
              setShowSaveModal(false);
            }}
            ripple="dark"
          >
            fermer
          </Button>

          <Button
            color="green"
            onClick={() => {
              genericPatchVisibility(
                props.Name,
                BACKEND_URL,
                visibilityChanges,
                token,
                setShowSaveModal,
                setVisibilityChanges,
                refresh
              );
            }}
            ripple="light"
          >
            Confirmer
          </Button>
        </DialogFooter>
      </Modal>
      <Modal size="lg" active={showModal} toggler={() => setShowModal(false)}>
        <DialogHeader toggler={() => setShowModal(false)}></DialogHeader>
        <DialogBody>
          <div className="flex flex-row w-full flex-wrap ">
            {![
              "user",
              "Video",
              "Tutorial",
              "Calendar",
              "adspy",
              "Category",
              "admin",
              "VideoMarketing",
              "VideoMiniInflu",
              "VideoEbook",
              "testimony",
            ].includes(props.Name) && (
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6 font-medium mr-3 my-auto w-80">
                  choisir une niche
                </div>
                {props.Name !== "user" && (
                  <Select
                    className={` basic-single w-full ${
                      newItem.Niche
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    }`}
                    classNamePrefix="select"
                    value={{
                      label: newItem.Niche || "",
                      value: newItem.Niche || "",
                    }}
                    onChange={(e) => setNewItem({ ...newItem, Niche: e.value })}
                    isClearable={false}
                    isSearchable={false}
                    name="color"
                    options={props.Category.map((i) => {
                      return { label: i.name, value: i.name };
                    })}
                  />
                )}
              </div>
            )}
            {props.columns
              .filter(
                (i) =>
                  i.text !== "Niche" &&
                  i.db !== "LastSeen" &&
                  i.db !== "customerId" &&
                  i.db !== "createdAt" &&
                  i.db !== "canceledAt" &&
                  i.db !== "byPassCouponCheck"
              )
              .map((i) => {
                if (
                  i.db === "registredViaClient" ||
                  i.db === "subscriptionStatus"
                )
                  return false;
                return (
                  <div className="flex flex-row w-1/2 mt-5">
                    <div
                      className={`ml-6 ${i.db === "VisibleAllTime" ? "font-bold" : "font-medium"} mr-3 my-auto w-80`}
                    >
                      {i.text}:
                    </div>
                    {i.type == "text" && i.db !== "Validity" && (
                      <input
                        placeholder={i.text}
                        value={newItem[i.db] || ""}
                        onChange={(e) =>
                          setNewItem({ ...newItem, [i.db]: e.target.value })
                        }
                        className={`p-1 w-full outline-none  ${
                          newItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg`}
                        rows="5"
                      />
                    )}
                    {i.type == "textOptions" && i.db !== "Validity" && (
                      <Select
                        placeholder={i.text}
                        value={
                          newItem[i.db]
                            ? { label: newItem[i.db], value: newItem[i.db] }
                            : { label: "", value: "" }
                        }
                        onChange={(e) =>
                          setNewItem({ ...newItem, [i.db]: e.value })
                        }
                        className={`p-1 w-full outline-none  ${
                          newItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg`}
                        options={i.options.map((op) =>
                          Object.create({ label: op, value: op })
                        )}
                      />
                    )}
                    {i.db == "Validity" && (
                      <input
                        type="date"
                        placeholder={i.text}
                        value={newItem[i.db] || ""}
                        onChange={(e) =>
                          setNewItem({ ...newItem, [i.db]: e.target.value })
                        }
                        className={`p-1 w-full outline-none  ${
                          newItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg`}
                        rows="5"
                      />
                    )}
                    {i.type == "link" && (
                      <input
                        placeholder={i.text}
                        value={newItem[i.db] || ""}
                        onChange={(e) =>
                          setNewItem({ ...newItem, [i.db]: e.target.value })
                        }
                        className={`p-1 w-full outline-none  ${
                          newItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg`}
                        rows="5"
                      />
                    )}
                    {i.type == "number" && (
                      <input
                        type="number"
                        placeholder={i.text}
                        value={newItem[i.db] || ""}
                        onChange={(e) =>
                          setNewItem({ ...newItem, [i.db]: e.target.value })
                        }
                        className={`p-1 w-full outline-none  ${
                          newItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg`}
                        rows="5"
                      />
                    )}
                    {i.type == "checkbox" && (
                      <div className="flex flex-row w-full">
                        <input
                          type="checkbox"
                          checked={newItem[i.db]}
                          disabled={
                            i.db !== "VisibleAllTime" &&
                            newItem["VisibleAllTime"]
                          }
                          onChange={(e) =>
                            setNewItem({ ...newItem, [i.db]: e.target.checked })
                          }
                          className={`p-1 w-4 h-4 outline-none  ${
                            newItem[i.db]
                              ? "border-green-400 border "
                              : "border-red-400 border-2 "
                          } rounded-lg my-auto`}
                        />
                      </div>
                    )}
                    {i.type == "file" && (
                      <input
                        name="file"
                        className={`w-full ${
                          newItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg p-1`}
                        type="file"
                        key={key}
                        onChange={(e) => {
                          e.preventDefault();
                          setNewItem({ ...newItem, [i.db]: e.target.files[0] });
                        }}
                      />
                    )}
                    {i.type == "multiple" && (
                      <Select
                        isMulti
                        name="file"
                        className={`w-full ${
                          newItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg p-1`}
                        key={key}
                        onChange={(e) => {
                          setNewItem({
                            ...newItem,
                            [i.db]: e.map((item) => item.value),
                          });
                        }}
                        value={
                          newItem[i.db]?.map((j) => {
                            return { value: j, label: j };
                          }) || []
                        }
                        options={[
                          "admin",
                          "prompts",
                          "products",
                          "suppliers",
                          "competitors",
                          "influencers",
                          "user",
                          "video",
                          "tutorial",
                          "calendar",
                          "adspy",
                          "plan",
                          "category",
                          "coupon",
                          "marketing",
                          "videoMarketing",
                          "lienMarketing",
                          "miniInflu",
                          "videoMiniInflu",
                          "lienMiniInflu",
                          "ebook",
                          "videoEbook",
                          "assets",
                          "testimony",
                          "statistics",
                          "userStatistics",
                          "seo",
                          "translate",
                        ].map((j) => {
                          return { label: j, value: j };
                        })}
                      />
                    )}
                  </div>
                );
              })}

            {props.Name == "user" && (
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">
                  Mot de passe
                </div>
                <input
                  placeholder={"Mot de passe "}
                  value={newItem?.password || ""}
                  onChange={(e) =>
                    setNewItem({ ...newItem, password: e.target.value })
                  }
                  className={`p-1 w-full outline-none  ${
                    newItem["password"]
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg my-auto`}
                  rows="5"
                />
              </div>
            )}
            {props.Name == "admin" && (
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">
                  Mot de passe
                </div>
                <input
                  placeholder={"Mot de passe "}
                  value={newItem?.Password || ""}
                  onChange={(e) =>
                    setNewItem({ ...newItem, Password: e.target.value })
                  }
                  className={`p-1 w-full outline-none  ${
                    newItem["password"]
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg my-auto`}
                  rows="5"
                />
              </div>
            )}
            {props.Name === "user" && (
              <text className=" mt-5">
                Veuillez vous assurer de donner une valeur supérieure à 0 pour
                seo Limit et prompt Limit, ou de les définir à 0 pour utiliser
                la valeur générale.
              </text>
            )}
            <div className="w-full flex flex-row justify-end">
              <Button
                onClick={(e) => setShowModal(false)}
                className="rounded-lg px-4 py-2 text-red-500 hover:bg-red-100 mr-3 font-semibold"
              >
                Annuler
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  const formData = new FormData();
                  props.columns.map((i) => {
                    if (i.type === "file" && newItem[i.db]?.name) {
                      formData.append(i.db, newItem[i.db], newItem[i.db]?.name);
                    } else if (i.db === "Validity") {
                      formData.append(i.db, new Date(newItem[i.db]));
                    } else if (i.db === "Permission") {
                      formData.append(i.db, JSON.stringify(newItem[i.db]));
                    } else {
                      formData.append(i.db, newItem[i.db]);
                    }
                  });
                  if (props.Name === "user") {
                    formData.append("password", newItem.password);
                    formData.delete("createdAt");
                    formData.delete("canceledAt");
                    formData.delete("customerId");
                    formData.delete("LastSeen");
                    formData.set("byPassCouponCheck", true);
                    for (const pair of formData.entries()) {
                      console.log(pair[0], pair[1]);
                    }
                  }
                  if (props.Name === "admin") {
                    formData.append("Password", newItem.Password);
                  }
                  axios
                    .post(
                      `${BACKEND_URL}/api/v1/${props.Name}`,
                      props.Name == "admin"
                        ? {
                            ...newItem,
                            Permission: newItem
                              ? JSON.stringify(newItem.Permission)
                              : null,
                          }
                        : formData,
                      {
                        headers: {
                          authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then((Response) => {
                      if (
                        Response.data &&
                        Response.data.hasOwnProperty("success") &&
                        !Response.data.success
                      ) {
                        toast.error(Response.data.message);
                        return;
                      }
                      refresh();
                      setNewItem({
                        Image: "",
                        ...(["fournisseur", "Concurrent"].includes(props.Name)
                          ? {}
                          : {}),
                      });
                      toast.success(
                        `🥳 un ${
                          props.Name == "user" ? "utilisateur" : props.Name
                        } a été crée`
                      );
                      setkey(null);
                      setShowModal(false);
                    })
                    .catch((err) => {
                      setNewItem({});
                      if (
                        err.response.data &&
                        err.response.data.hasOwnProperty("success") &&
                        !err.response.data.success
                      ) {
                        toast.error(err.response.data.message);
                      } else {
                        toast.error("😕 une erreur est survenue ");
                      }
                      setShowModal(false);
                    });
                }}
                className=" mt-10 rounded-lg px-4 py-2 bg-green-500 text-white font-semibold hover:text-gray-700 hover:bg-green-100 ml-6"
              >
                Créer
              </Button>
            </div>
          </div>
        </DialogBody>
        <DialogFooter></DialogFooter>
      </Modal>
      <Modal
        size="lg"
        active={showEditModal}
        toggler={() => setShowEditModal(false)}
      >
        <DialogHeader toggler={() => setShowEditModal(false)}></DialogHeader>
        <DialogBody>
          <div className="flex flex-row w-full flex-wrap ">
            {![
              "user",
              "Video",
              "Tutorial",
              "Calendar",
              "adspy",
              "Category",
              "admin",
              "VideoMarketing",
              "VideoMiniInflu",
              "VideoEbook",
              "testimony",
            ].includes(props.Name) && (
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">Niche</div>
                <Select
                  className={` basic-single w-full ${
                    selectedItem.Niche
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  }`}
                  value={{
                    label: selectedItem.Niche,
                    value: selectedItem.Niche,
                  }}
                  classNamePrefix="select"
                  onChange={(e) =>
                    setselectedItem({ ...selectedItem, Niche: e.value })
                  }
                  isClearable={false}
                  isSearchable={false}
                  name="color"
                  options={props.Category.map((i) => {
                    return { label: i.name, value: i.name };
                  })}
                />
              </div>
            )}
            {props.columns
              .filter(
                (i) =>
                  i.text !== "Niche" &&
                  i.db !== "LastSeen" &&
                  i.db !== "customerId" &&
                  i.db !== "createdAt" &&
                  i.db !== "canceledAt"
              )
              .map((i) => {
                if (
                  i.db === "registredViaClient" ||
                  i.db === "subscriptionStatus"
                )
                  return false;
                if (i.db == "customerId")
                  return (
                    <div className="flex flex-row w-1/2 mt-5 order-0 basis-full	">
                      <div className="ml-6  font-medium mr-3 my-auto">
                        {i.text}:
                      </div>
                      <span className="bg-blue-700 text-white w-auto">
                        {" "}
                        {selectedItem[i.db]}
                      </span>
                    </div>
                  );
                return (
                  <div className="flex flex-row w-1/2 mt-5">
                    <div
                      className={`ml-6 ${i.db === "VisibleAllTime" ? "font-bold" : "font-medium"} mr-3 my-auto w-80`}
                    >
                      {i.text}
                    </div>
                    {i.type == "text" && i.db !== "Validity" && (
                      <input
                        placeholder={i.text}
                        value={selectedItem[i.db]}
                        onChange={(e) =>
                          setselectedItem({
                            ...selectedItem,
                            [i.db]: e.target.value,
                          })
                        }
                        className={`p-1 w-full outline-none  ${
                          selectedItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg`}
                        rows="5"
                      />
                    )}
                    {i.type == "multiple" && (
                      <Select
                        isMulti
                        name="file"
                        className={`w-full ${
                          selectedItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg p-1`}
                        key={key}
                        onChange={(e) => {
                          setselectedItem({
                            ...selectedItem,
                            [i.db]: e.map((item) => item.value),
                          });
                        }}
                        value={
                          selectedItem[i.db]?.map((j) => {
                            return { value: j, label: j };
                          }) || []
                        }
                        options={[
                          "admin",
                          "prompts",
                          "products",
                          "suppliers",
                          "competitors",
                          "influencers",
                          "user",
                          "video",
                          "tutorial",
                          "calendar",
                          "adspy",
                          "plan",
                          "category",
                          "coupon",
                          "marketing",
                          "videoMarketing",
                          "lienMarketing",
                          "miniInflu",
                          "videoMiniInflu",
                          "lienMiniInflu",
                          "ebook",
                          "videoEbook",
                          "assets",
                          "testimony",
                          "statistics",
                          "userStatistics",
                          "translate",
                        ].map((j) => {
                          return { label: j, value: j };
                        })}
                      />
                    )}
                    {i.type == "textOptions" && i.db !== "Validity" && (
                      <Select
                        placeholder={i.text}
                        value={
                          selectedItem[i.db]
                            ? {
                                label: selectedItem[i.db],
                                value: selectedItem[i.db],
                              }
                            : { label: "", value: "" }
                        }
                        onChange={(e) =>
                          setselectedItem({
                            ...selectedItem,
                            [i.db]: e.value,
                          })
                        }
                        className={`p-1 w-full outline-none  ${
                          selectedItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg`}
                        options={i.options.map((op) =>
                          Object.create({ label: op, value: op })
                        )}
                      />
                    )}
                    {i.db == "Validity" && (
                      <input
                        type="date"
                        value={
                          selectedItem[i.db]?.includes("T")
                            ? selectedItem[i.db].split("T")[0]
                            : selectedItem[i.db]
                        }
                        onChange={(e) => {
                          /* console.log(
                          typeof e.target.valueAsDate.toLocaleDateString()
                        );
                        console.log(new Date(e.target.value).toJSON());
                        */
                          setselectedItem({
                            ...selectedItem,
                            [i.db]: new Date(e.target.value).toJSON(),
                          });
                        }}
                        className={`p-1 w-full outline-none  ${
                          selectedItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg`}
                        rows="5"
                      />
                    )}
                    {i.type == "link" && (
                      <input
                        placeholder={i.text}
                        value={selectedItem[i.db]}
                        onChange={(e) =>
                          setselectedItem({
                            ...selectedItem,
                            [i.db]: e.target.value,
                          })
                        }
                        className={`p-1 w-full outline-none  ${
                          selectedItem[i.db]
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg`}
                        rows="5"
                      />
                    )}
                    {i.type == "number" && (
                      <input
                        type="number"
                        placeholder={i.text}
                        value={selectedItem[i.db] || 0}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            setselectedItem({
                              ...selectedItem,
                              [i.db]: e.target.value,
                            });
                          }
                        }}
                        className={`p-1 w-full outline-none ${
                          i.db === "promptLimit" ||
                          i.db === "seoLimit" ||
                          (selectedItem[i.db] !== null &&
                            selectedItem[i.db] !== "")
                            ? "border-green-400 border"
                            : "border-red-400 border-2"
                        } rounded-lg`}
                        rows="5"
                      />
                    )}
                    {i.type === "checkbox" && (
                      <div className="flex flex-row w-full ">
                        <input
                          type="checkbox"
                          checked={selectedItem[i.db]}
                          disabled={
                            selectedItem["VisibleAllTime"] &&
                            i.db !== "VisibleAllTime"
                          }
                          onChange={(e) =>
                            setselectedItem({
                              ...selectedItem,
                              [i.db]: e.target.checked,
                            })
                          }
                          className={`p-1 w-4 h-4 outline-none ${
                            selectedItem[i.db]
                              ? "border-green-400 border "
                              : "border-red-400 border-2 "
                          } rounded-lg my-auto`}
                          rows="5"
                        />
                      </div>
                    )}
                    {i.type == "file" && !i.pdf && (
                      <div className="flex flex-col w-full justify-center flex-wrap">
                        {typeof selectedItem.Image == "string" && (
                          <img
                            height="120"
                            className="mx-auto"
                            width="120"
                            src={`${selectedItem.Image}`}
                          />
                        )}
                        {typeof selectedItem.Image == "string" && (
                          <div className="mx-auto">ou</div>
                        )}
                        <input
                          name="file"
                          className={` w-2/3 mx-auto ${
                            selectedItem[i.db]
                              ? "border-green-400 border "
                              : "border-red-400 border-2 "
                          } rounded-lg p-1`}
                          type="file"
                          onChange={(e) => {
                            e.preventDefault();
                            setselectedItem({
                              ...selectedItem,
                              Image: e.target.files[0],
                            });
                          }}
                        />
                      </div>
                    )}
                    {i.type == "file" && i.pdf && (
                      <div className="flex flex-col w-full justify-center flex-wrap">
                        {typeof selectedItem.Image == "string" && (
                          <a
                            className="text-blue-400"
                            href={`${selectedItem.Image}`}
                          >
                            Lien pdf
                          </a>
                        )}
                        {typeof selectedItem.Image == "string" && (
                          <div className="mx-auto">ou</div>
                        )}
                        <input
                          name="file"
                          className={` w-2/3 mx-auto ${
                            selectedItem[i.db]
                              ? "border-green-400 border "
                              : "border-red-400 border-2 "
                          } rounded-lg p-1`}
                          type="file"
                          onChange={(e) => {
                            e.preventDefault();
                            setselectedItem({
                              ...selectedItem,
                              Image: e.target.files[0],
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            {props.Name == "admin" &&
              props.admin &&
              selectedItem.id == props.admin.id && (
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Mot de passe
                  </div>
                  <input
                    placeholder={"Mot de passe "}
                    value={selectedItem?.Password || ""}
                    onChange={(e) =>
                      setselectedItem({
                        ...selectedItem,
                        Password: e.target.value,
                      })
                    }
                    className={`p-1 w-full outline-none  ${
                      selectedItem["Password"]
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg my-auto`}
                  />
                </div>
              )}
            {props.Name === "user" && (
              <text className=" mt-5">
                Veuillez vous assurer de donner une valeur supérieure à 0 pour
                seo Limit et prompt Limit, ou de les définir à 0 pour utiliser
                la valeur générale.
              </text>
            )}
          </div>
        </DialogBody>
        <DialogFooter>
          {!(
            props.Name == "admin" &&
            props.admin &&
            selectedItem.id == props.admin.id
          ) && (
            <Button
              color="red"
              buttonType="link"
              onClick={() => {
                setShowConfirmDeleteModal(true);
              }}
              ripple="dark"
            >
              Supprimer
            </Button>
          )}

          <Button
            color="green"
            onClick={(e) => {
              e.preventDefault();
              if (selectedItem?.Validity) {
                if (typeof selectedItem?.Validity == "string") {
                  //console.log(new Date(selectedItem?.Validity).toDateString());
                  /* setselectedItem({
                    ...selectedItem,
                    Validity: new Date(selectedItem?.Validity),
                  }); */
                }
              }
              //console.log("test", selectedItem.Validity);
              const formData = new FormData();
              Object.keys(selectedItem).map((i) => {
                //  console.log(typeof selectedItem.Image);
                if (i !== "Permission") {
                  if (i === "Image") {
                    if (typeof selectedItem.Image == "object") {
                      // console.log("yes an object");
                      formData.append(
                        "Image",
                        selectedItem.Image,
                        selectedItem.Image.name
                      );
                    }
                  } else if (i.db === "Validity") {
                    formData.append(i.db, selectedItem[i.db]);
                  } else if (i.db === "Permission") {
                    formData.append(
                      i.db,
                      JSON.stringify(selectedItem.Permission)
                    );
                  } else if (i.db === "LastSeen") {
                    formData.append(i.db, new Date());
                  } else {
                    formData.append(i, selectedItem[i]);
                  }
                }
              });

              if (Object.keys(selectedItem).includes("Permission")) {
                formData.append(
                  "Permission",
                  JSON.stringify(selectedItem.Permission)
                );
              }

              axios
                .put(
                  `${BACKEND_URL}/api/v1/${props.Name}`,
                  props.Name === "admin"
                    ? {
                        ...selectedItem,
                        Permission: JSON.stringify(selectedItem.Permission),
                      }
                    : formData,
                  {
                    headers: {
                      authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  refresh();
                  if (
                    response.data &&
                    response.data.hasOwnProperty("success") &&
                    !response.data.success
                  ) {
                    toast.error(response.data.message);
                    return;
                  } else {
                    toast.success(`🥳 un ${props.Name} a été mis à jour`);
                    setShowEditModal(false);
                    setselectedItem({
                      ...(["fournisseur", "Concurrent"].includes(props.Name)
                        ? {}
                        : {}),
                    });
                  }
                })
                .catch((err) => {
                  if (
                    err.response.data &&
                    err.response.data.hasOwnProperty("success") &&
                    !err.response.data.success
                  ) {
                    toast.error(err.response.data.message);
                  } else {
                    toast.error("😕 une erreur est survenue ");
                  }
                });
            }}
            ripple="light"
          >
            Sauvegarder
          </Button>
        </DialogFooter>
      </Modal>

      <Modal
        size="md"
        active={showConfirmDeleteModal}
        toggler={() => setShowConfirmDeleteModal(false)}
      >
        <DialogHeader
          toggler={() => setShowConfirmDeleteModal(false)}
        ></DialogHeader>
        <DialogBody>
          <h1 className="text-red-700 text-xl">
            Êtes-vous sûr de supprimer cet {props.Name} ?
          </h1>
          {props.Name === "user" && (
            <div className="flex mt-10 items-center justify-around">
              <span>
                Pour supprimer les cartes de cet utilisateur, merci de cocher
                cette case
              </span>
              <input
                type="checkbox"
                className="h-4 w-4 bg-blue-200 outline-none"
                checked={deleteFingerprints}
                onClick={(e) => {
                  setDeleteFingerprints(e.target.checked);
                }}
              />
            </div>
          )}
        </DialogBody>
        <DialogFooter>
          {!(
            props.Name == "admin" &&
            props.admin &&
            selectedItem.id == props.admin.id
          ) && (
            <Button
              color="red"
              buttonType="link"
              onClick={() => {
                setShowConfirmDeleteModal(false);
              }}
              ripple="dark"
            >
              Non
            </Button>
          )}

          <Button
            color="green"
            onClick={(e) =>
              axios
                .post(
                  `${BACKEND_URL}/api/v1/${props.Name}delete`,
                  {
                    ...selectedItem,
                    ...(props.Name === "user" && {
                      deleteCards: deleteFingerprints,
                    }),
                  },
                  {
                    headers: {
                      authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  refresh();
                  toast.success(
                    `🥳 un ${
                      props.Name == "user" ? "utilisateur" : props.Name
                    } a été supprimé`
                  );
                  setShowConfirmDeleteModal(false);
                  setShowEditModal(false);
                  setselectedItem({
                    ...(["fournisseur", "Concurrent"].includes(props.Name)
                      ? {}
                      : {}),
                  });
                })
                .catch((err) => {
                  if (
                    err.response.data &&
                    err.response.data.hasOwnProperty("success") &&
                    !err.response.data.success
                  ) {
                    toast.error(err.response.data.message);
                    return;
                  }
                  toast.error("😕 une erreur est survenue ");
                })
            }
            ripple="light"
          >
            Oui
          </Button>
        </DialogFooter>
      </Modal>
    </div>
  );
}
