import { generateRandomColors, getDate } from '../functions';

import { Bar } from 'react-chartjs-2';

const BarChart = ({ statistics, selectedUser, selectedDate, selectedMode }) => {
  const promptsUsage = statistics.promptUsage;
  const totalPrompts = statistics.totalPrompts;

  const { colors, borderColors } = generateRandomColors(
    Object.keys(promptsUsage || {}).length,
  );

  const data = {
    labels: Object.keys(promptsUsage || {}),
    datasets: [
      {
        label: '',
        data: Object.keys(promptsUsage || {}).map((key) => promptsUsage[key]),
        backgroundColor: colors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="w-[60%] bg-white p-4 rounded-md">
      <div className="p-2 text-center">
        <p className="text-md border-b-2 pb-2 border-gray-50">
          <span className="">Total prompts utilisés </span>
          <span className="font-semibold text-blue-600">
            {getDate(selectedDate, selectedMode)}{' '}
          </span>
          par
          <span className="font-semibold text-blue-600">
            {selectedUser.id
              ? ` ${selectedUser.firstName} ${selectedUser.lastName}`
              : ' Toutes les utilisateurs'}
          </span>{' '}
          et <span className="font-bold text-green-600">{totalPrompts}</span>{' '}
          prompt
        </p>
      </div>
      <Bar
        options={{
          responsive: true,
          plugins: {
            datalabels: {
              color: '#000',
              font: {
                weight: 'bold',
              },
              formatter: (value, context) => {
                return value;
              },
            },
            legend: {
              position: 'top',
            },
            title: {
              display: false,
            },
          },
        }}
        data={data}
        className="p-8"
      />
    </div>
  );
};

export default BarChart;
