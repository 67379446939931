import axios from "axios";
import toast from "react-hot-toast";

const generateNewVisibility = (name, i, valueToChange, fullValueToChange) => {
  let newName;
  switch (name) {
    case "Video":
    case "Tutorial":
    case "VideoMarketing":
    case "VideoMiniInflu":
    case "MiniInflulinks":
    case "Marketinglinks":
    case "VideoEbook":
    case "testimony":
      newName = i.Title;
      break;

    case "Calendar":
    case "adspy":
      newName = i.Lien;
      break;

    case "Marketing":
    case "MiniInflu":
    case "Ebook":
      newName = i.Name;
      break;

    default:
      newName = i[name];
      break;
  }

  return {
    [i.id]: {
      name: newName,
      old: {
        allTime: i.VisibleAllTime,
      },
      new: {
        allTime:
          valueToChange === "allTime"
            ? !i[fullValueToChange]
            : i.VisibleAllTime,
      },
    },
  };
};
const compareVisibility = (object, id, visibilityChanges) =>
  visibilityChanges[id].old.allTime === object[id].new.allTime &&
  visibilityChanges[id].old.thisMonth === object[id].new.thisMonth &&
  visibilityChanges[id].old.nextMonth === object[id].new.nextMonth;

const genericVisibilityChange = (
  name,
  item,
  valueToChange,
  fullValueToChange,
  visibilityChanges,
  setVisibilityChanges,
  Data,
  setData
) => {
  if (visibilityChanges.hasOwnProperty(item.id)) {
    const newObj = visibilityChanges;
    newObj[item.id].new[valueToChange] = !newObj[item.id].new[valueToChange];
    compareVisibility(newObj, item.id, visibilityChanges) &&
      delete newObj[item.id];

    setVisibilityChanges({
      ...newObj,
    });
  } else {
    const newObj = generateNewVisibility(
      name,
      item,
      valueToChange,
      fullValueToChange
    );
    setVisibilityChanges({
      ...visibilityChanges,
      ...newObj,
    });
  }
  let newData = Data.map((element) => {
    if (element.id === item.id) {
      element[fullValueToChange] = !item[fullValueToChange];
    }
    return element;
  });
  setData(newData);
};

const genericPatchVisibility = (
  name,
  BACKEND_URL,
  visibilityChanges,
  token,
  setShowSaveModal,
  setVisibilityChanges,
  refresh
) => {
  axios
    .patch(`${BACKEND_URL}/api/v1/${name}/visibility`, visibilityChanges, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      refresh();
      setShowSaveModal(false);
      setVisibilityChanges({});
      toast.success(response.data.message);
    })
    .catch((err) => {
      toast.error("😕 une erreur est survenue ");
    });
};
export { genericVisibilityChange, genericPatchVisibility };
