import { ToastContainer } from "react-toastify";

const Layout = ({ children }) => {
  return (
    <div className="flex h-screen overflow-hidden justify-center bg-gray-100">
      <div className="flex-1 overflow-auto max-w-screen-2xl">{children}</div>
      <ToastContainer />
    </div>
  );
};

export default Layout;
